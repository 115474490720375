var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cont-video" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.videoLoaded,
            expression: "!videoLoaded",
          },
        ],
        staticClass: "facade",
        staticStyle: { position: "relative" },
      },
      [
        _c("v-img", {
          staticClass: "facade-child",
          attrs: {
            src: `https://vumbnail.com/${_vm.src}.png`,
            cover: "",
            "aspect-ratio": 1,
            alt: "Video placeholder",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.videoLoaded,
            expression: "videoLoaded",
          },
        ],
        staticStyle: { padding: "72% 0 0 0", position: "relative" },
      },
      [
        _c("iframe", {
          staticClass: "vimeo-iframe",
          staticStyle: {
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          },
          attrs: {
            src:
              "https://player.vimeo.com/video/" +
              _vm.src +
              "?muted=1&badge=0&autopause=0&player_id=0&app_id=58479=58479&dnt=1&autoplay=" +
              _vm.autoplay,
            frameborder: "0",
            allow: "autoplay; fullscreen; picture-in-picture; clipboard-write",
            allowfullscreen: "",
            webkitallowfullscreen: "",
            mozallowfullscreen: "",
            title: "Volumiq V5",
          },
          on: { load: _vm.iframeIsLoaded },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }