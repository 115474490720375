import { fetch } from '@/tools/axios'
import { globalStore } from '../store/store'

const api = process.env.VUE_APP_API_URL

export const loginFBCall = () => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/auth/facebook`, options)
}

export const logoutCall = () => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/auth/logout`, options).then((res) => {
		globalStore.commit('logout')
		return res
	})
}

export const getMe = () => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/auth/me`, options)
		.then((res) => {
			globalStore.commit('reloadUser', res.user)
			return res
		})
}

export const checkUsernameAvailable = (username) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: { username }
	}
	return fetch(`${api}/user/accountExist`, options)
}

export const deleteAccount = () => {
	const options = {
		method: 'DELETE',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/user/delete`, options).then((res) => {
		globalStore.commit('logout')
		return res
	})
}

export const updateAccountInfos = (user) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: user
	}
	return fetch(`${api}/user/update`, options).then((res) => {
		globalStore.commit('reloadUser', res.user)
		return res
	})
}

export const signup = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: body
	}
	return fetch(`${api}/auth/signup`, options).then((res) => {
		globalStore.commit('reloadUser', res.user)
		return res
	})
}
export const login = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: body
	}
	return fetch(`${api}/auth/login`, options).then((res) => {
		globalStore.commit('reloadUser', res.user)
		return res
	})
}

export const lostPassword = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: body
	}
	return fetch(`${api}/auth/lostpwd`, options)
}

export const lostPasswordChange = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: body
	}
	return fetch(`${api}/auth/lostpwdchange`, options).then((res) => {
		globalStore.commit('reloadUser', res.user)
		return res
	})
}

export const activateAccount = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: body
	}
	return fetch(`${api}/auth/activate-account`, options).then((res) => {
		globalStore.commit('reloadUser', res.user)
		return res
	})
}

export const referral = (code) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body: { referralcode: code }
	}
	return fetch(`${api}/user/referral`, options)
}