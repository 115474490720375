var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    {
      staticClass: "images-block mt-3 pt-n5 box-radius",
      class: {
        "images-block-right-corner": !_vm.IsMobile && _vm.tutoIndex === 4,
        "images-block-left-corner": !_vm.IsMobile && _vm.tutoIndex === 0,
      },
      attrs: { cols: "12" },
    },
    [
      _c(
        "v-row",
        { staticClass: "mt-n5 mb-10 inside" },
        [
          _c("tutoBlockRoomBuilding", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tutoIndex === 0,
                expression: "tutoIndex === 0",
              },
            ],
          }),
          _c("tutoBlockFurnishing", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tutoIndex === 1,
                expression: "tutoIndex === 1",
              },
            ],
          }),
          _c("tutoBlockDeco", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tutoIndex === 2,
                expression: "tutoIndex === 2",
              },
            ],
          }),
          _c("tutoBlockExperience", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tutoIndex === 3,
                expression: "tutoIndex === 3",
              },
            ],
          }),
          _c("tutoBlockFinalizing", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tutoIndex === 4,
                expression: "tutoIndex === 4",
              },
            ],
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }