import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import fr from 'vuetify/es5/locale/fr'

import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export default new Vuetify({
	theme: {
		options: {
			customProperties: true
		},
		themes: {
			light: {
				primary: '#4caf50',
				secondary: '#4caf50',
				tertiary: '#495057',
				accent: '#82B1FF',
				error: '#f55a4e',
				info: '#00d3ee',
				success: '#5cb860',
				warning: '#d50000',
				blue: '#2196F3',
				yellow: '#FABE69',
				orange: '#D56F4B',
				pink: '#FF2D55',
				lightBlue: '#C7EAF9',
				lighterBlue: '#EAF9FF',
				green: '#b7dea9',
				red: '#E02C38',
				lightGrey: '#f0eff5',
				grey: '#cecdd2',
				white: '#FFFFFF',
				darkGrey: '#8f8e94',
				black: '#000000'
			},
			dark: {
				primary: '#4caf50',
				secondary: '#4caf50',
				tertiary: '#495057',
				accent: '#82B1FF',
				error: '#f55a4e',
				info: '#00d3ee',
				success: '#5cb860',
				warning: '#d50000',
				blue: '#2196F3',
				yellow: '#FABE69',
				orange: '#D56F4B',
				pink: '#FF2D55',
				lightBlue: '#C7EAF9',
				lighterBlue: '#EAF9FF',
				green: '#b7dea9',
				red: '#E02C38',
				lightGrey: '#f0eff5',
				grey: '#cecdd2',
				white: '#FFFFFF',
				darkGrey: '#8f8e94',
				black: '#000000'
			}
		}
	},
	lang: {
		locales: { fr },
		current: 'fr'
	},
	icons: {
		iconfont: 'mdi'
	}
})
