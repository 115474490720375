var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-dialog",
        {
          attrs: {
            transition: "dialog-bottom-transition",
            "max-width": "600",
            persistent: "",
          },
          model: {
            value: _vm.newAccDialogReferral,
            callback: function ($$v) {
              _vm.newAccDialogReferral = $$v
            },
            expression: "newAccDialogReferral",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "popup" },
            [
              _c(
                "v-row",
                {
                  staticClass: "poprows text-center ma-0 pa-12 pb-7",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c("v-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "h2" }, [
                      _c("b", {
                        directives: [
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("account.referral"),
                            expression: "$t('account.referral')",
                          },
                        ],
                      }),
                    ]),
                  ]),
                  _c("v-col", { staticClass: "mt-n5", attrs: { cols: "12" } }, [
                    _c("h3", {
                      directives: [
                        {
                          name: "sanitize",
                          rawName: "v-sanitize",
                          value: _vm.$t("account.referraluse", { nb: 1000 }),
                          expression: "$t('account.referraluse', { nb: 1000 })",
                        },
                      ],
                    }),
                  ]),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", xl: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          color: "black",
                          "hide-details": "",
                          dense: "",
                          outlined: "",
                          placeholder: "VOLUMIQ - #### - #### - ####",
                        },
                        model: {
                          value: _vm.referralcode,
                          callback: function ($$v) {
                            _vm.referralcode = $$v
                          },
                          expression: "referralcode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-btn", {
                        directives: [
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("global.ok"),
                            expression: "$t('global.ok')",
                          },
                        ],
                        staticClass: "cta-btn white--text",
                        on: {
                          click: function ($event) {
                            return _vm.sendReferral(_vm.referralcode)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("v-btn", {
                        directives: [
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("global.ignore"),
                            expression: "$t('global.ignore')",
                          },
                        ],
                        staticClass: "cta-btn white--text",
                        on: {
                          click: function ($event) {
                            return _vm.sendReferral(null)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { transition: "dialog-bottom-transition", "max-width": "600" },
          model: {
            value: _vm.referralSuccessModal,
            callback: function ($$v) {
              _vm.referralSuccessModal = $$v
            },
            expression: "referralSuccessModal",
          },
        },
        [
          _c("mainCoinsModal", {
            attrs: { nb: 1000, "is-bonus": true, "is-referral": true },
          }),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: "1000" },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _c("h3", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.$t("account.referralinvalid"),
                expression: "$t('account.referralinvalid')",
              },
            ],
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }