import Vue from "vue";
import Vuex from "vuex";
import "@mdi/font/css/materialdesignicons.css";
import "./plugins";
import VueI18n from "vue-i18n";
import VueMeta from 'vue-meta';
// import VueAnalytics from "vue-analytics";
import LoadScript from "vue-plugin-load-script";
import "@/styles/global.scss";
import VueFlags from "@growthbunker/vueflags";
import VSanitize from "v-sanitize";
import globalStorePlugin from "./plugins/globalStorePlugin";
import vuetify from "./plugins/vuetify";
import router from "./router";
import App from "./App.vue";
import VueSocialSharing from 'vue-social-sharing'
/// /////////////// languages
import i18n from "@/plugins/i18n";

/// ///////////// Test WebP
const WebpConverter = {
  install(Vue) {
    Vue.prototype.GetImgType = function (a) {
      if (window.hasWebP) {
        return a.replace(".png", ".webp").replace(".jpeg", ".webp").replace(".jpg", ".webp");
      } else
        return a;
    };
  },
};


window.hasWebP = null;

window.WebPSupport = () => {
  return new Promise((resolve, reject) => {
    if (window.hasWebP != null) {
      resolve(window.hasWebP)
    }
    const img = new Image();
    img.onload = () => {
      window.hasWebP = !!(img.height > 0 && img.width > 0);
      resolve(window.hasWebP)
    };
    img.onerror = () => {
      window.hasWebP = false;
      resolve(window.hasWebP)
    };
    img.src = "/img/test.webp";
  })

}
window.WebPSupport().then(() => {

  Vue.use(VueMeta);

  Vue.use(VSanitize);
  VSanitize.defaults.allowedAttributes["*"] = ["class"];

  Vue.use(VueFlags, {
    // Specify the path of the folder where the flags are stored.
    iconPath: "/img/flags/",
  });
  Vue.use(require("vue-cookies"));
  Vue.use(Vuex);
  Vue.use(globalStorePlugin);
  Vue.use(VueI18n);
  Vue.use(VueSocialSharing);
  Vue.use(WebpConverter);

  /// ///////////// Check authentification
  const token = localStorage.getItem("token");
  if (token) {
    Vue.prototype.$http.defaults.headers.common.Authorization = token;
  }
  // ---------------------
  Vue.config.productionTip = false;

  new Vue({
    router,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");

  /// ///////////// Analytics

  // Vue.use(VueAnalytics, {
  // 	id: "UA-130941775-1",
  // 	checkDuplicatedScript: true,
  // 	disabled: true,
  // 	router,
  // });

  Vue.use(router);

});
