import Vue from "vue";
import VueRouter from "vue-router";
const routes = require('./routes');
import { getMe } from "@/api/account";
import { globalStore } from "../store/store";
import i18n from "@/plugins/i18n";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};


Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    {
      path: '/:lang',
      component: {
        render(c) { return c('router-view') }
      },
      children: [...routes]
    }
  ],
});

const a = globalStore;

router.beforeEach((to, from, next) => {
  const lang = to.params.lang;
  if (['en', 'fr'].includes(lang)) {
    i18n.locale = lang;
    next();
  } else {
    if (localStorage.lang)
      next(`/${localStorage.lang}${to.fullPath}`);
    else
      next(`/${i18n.locale}${to.fullPath}`);
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  loggedIn((logged) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!logged) {
        router.push({ name: "Volumiq" });
      } else {
        if (!a.getters.userHasConfirmedAgreement && to.name != "Agreement") {
          router.push({ name: "Agreement" });
        } else if (a.getters.userHasConfirmedAgreement && to.name == "Agreement") {
          router.push({ name: "Projects" });
        } else {
          next();
        }
        next();
      }
    } else {
      next();
    }
  });
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
    const title = document.createElement("TITLE");
    title.innerHTML = nearestWithTitle.meta.title;
  } else if (previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
    const title = document.createElement("TITLE");
    title.innerHTML = nearestWithTitle.meta.title;
  }
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if (!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  })
    .forEach(tag => document.head.appendChild(tag));
  next();
});

router.beforeEach((to, from, next) => {
  const oldRoute = localStorage._oldRoute ? JSON.parse(localStorage._oldRoute) : null;

  if (oldRoute && oldRoute.name === to.name) {
    delete localStorage._oldRoute;
    return next();
  }

  const newRoute = {
    name: to.name,
    params: to.params,
  };
  localStorage._oldRoute = JSON.stringify(newRoute);

  next();
});

let loggedIn = (next) => {
  getMe().then((res) => {
    if (next) {
      next(res.logged);
    }
  });
};

export default router;
