<template>
  <div>
    <v-col v-show="showMe" sm="auto" class="ma-0 pa-0">
      <div v-if="greyedOut" v-sanitize="$t(textCode)" class="h4 my-1 poppins">
      </div>
      <div v-else-if="icon != null" class="h4 my-1 poppins">
        <router-link v-if="!externalLink" :to="redirect">
          <div :class="computeClasses">
            <v-icon size="20" class="mr-2">
              {{ icon }}
            </v-icon>
            <span v-sanitize="$t(textCode)" />
          </div>
        </router-link>
        <v-btn depressed text v-else-if="scroller" @click="goto()">
          <div :class="computeClasses">
            <v-icon size="20" class="mr-2">
              {{ icon }}
            </v-icon>
            <span v-sanitize="$t(textCode)" />
          </div>
        </v-btn>
        <v-btn depressed text v-else @click="window.open(redirection, '_blank', 'noopener')">
          <div :class="computeClasses">
            <v-icon size="20" class="mr-2">
              {{ icon }}
            </v-icon>
            <span v-sanitize="$t(textCode)" />
          </div>
        </v-btn>
      </div>
      <div v-else class="h4 my-1 poppins">
        <router-link v-if="!externalLink" :to="redirect">
          <div v-sanitize="$t(textCode)" :class="computeClasses" />
          <v-icon v-show="externalLink" class="mt-3 mx-1" small>
            mdi-open-in-new
          </v-icon>
        </router-link>
        <v-btn block text class="ma-0 pa-0 d-flex justify-space-between" v-else-if="scroller" @click="goto()">
            <div v-sanitize="$t(textCode)" :class="computeClasses" />
            <v-icon v-show="externalLink" class="mt-3 mx-1" small>
              mdi-open-in-new
            </v-icon>
        </v-btn>
        <v-btn block text class="ma-0 pa-0 d-flex justify-space-between" v-else @click="window.open(redirection, '_blank', 'noopener')">
            <div v-sanitize="$t(textCode)" :class="computeClasses" />
            <v-icon v-show="externalLink" class="mt-3 mx-1" small>
              mdi-open-in-new
            </v-icon>
        </v-btn>

      </div>
    </v-col>
    <div v-show="showMe && addDivider">
      <v-divider />
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    textCode: String,
    redirection: String,
    needLogged: Boolean,
    scroller: Boolean,
    externalLink: Boolean,
    greyedOut: Boolean,
    icon: String,
    addDivider: Boolean,
  },
  data() {
    return {
      window: window,
    };
  },
  computed: {
    showMe() {
      if (this.needLogged) return this.isLogged;
      return true;
    },
    redirect() {
      var u = this.redirection;
      if (!this.redirection) return "#";
      this.dialog = false;
      return { name: u };
    },
    computeClasses() {
      let str = "pa-2 dropdown-item item";
      if (this.greyedOut) str += " greyedout";
      if (this.redirection == this.$route.name) str += " active";
      return str;
    },
    isLogged() {
      return (
        this.$userStore.state.user != undefined &&
        Object.keys(this.$userStore.state.user).length > 0
      );
    },
  },
  watch: {},
  methods: {
    goto() {
      this.$vuetify.goTo(this.redirection);
      setTimeout(() => this.$vuetify.goTo(this.redirection), 500);
    },
  },
};
</script>

<style scoped>
a {
  text-transform: unset;
  color: unset !important;
}

.v-divider {
  border-color: var(--divider-color);
}

.theme--light.v-icon {
  color: unset !important;
  margin-right: 4px;
}

.phone-size .h7 {
  font-size: 1rem !important;
  margin: 0px !important;
}

.greyedout {
  color: grey !important;
}

.active {
  font-family: "Inter-Bold" !important;
}

.item:not(:hover) {
  background-color: transparent !important;
}
</style>
