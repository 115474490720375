var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "my-0 mx-0 pb-0 px-0 mt-n12", attrs: { fluid: "" } },
    [
      _c("div", {
        directives: [
          {
            name: "sanitize",
            rawName: "v-sanitize",
            value: _vm.$t("tutorials.selector1"),
            expression: "$t('tutorials.selector1')",
          },
        ],
        staticClass: "text-center",
      }),
      _c(
        "v-row",
        { staticClass: "my-0 mx-0 pb-0 px-0 justify-space-around" },
        [
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "6", lg: "3" },
            },
            [
              _c("videoBlock", {
                staticStyle: { width: "95%" },
                attrs: { src: 547554110 },
              }),
              _c("div", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("tutorials.selector2"),
                    expression: "$t('tutorials.selector2')",
                  },
                ],
                staticClass: "mt-1",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "6", lg: "3" },
            },
            [
              _c("videoBlock", {
                staticStyle: { width: "95%" },
                attrs: { src: 547554148 },
              }),
              _c("div", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("tutorials.selector3"),
                    expression: "$t('tutorials.selector3')",
                  },
                ],
                staticClass: "mt-1",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "6", lg: "3" },
            },
            [
              _c("videoBlock", {
                staticStyle: { width: "95%" },
                attrs: { src: 547554183 },
              }),
              _c("div", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("tutorials.selector4"),
                    expression: "$t('tutorials.selector4')",
                  },
                ],
                staticClass: "mt-1",
              }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "text-center",
              attrs: { cols: "12", sm: "6", lg: "3" },
            },
            [
              _c("videoBlock", {
                staticStyle: { width: "95%" },
                attrs: { src: 547554236 },
              }),
              _c("div", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("tutorials.selector5"),
                    expression: "$t('tutorials.selector5')",
                  },
                ],
                staticClass: "mt-1",
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "h3 text-center mb-14" }, [
        _c("b", {
          directives: [
            {
              name: "sanitize",
              rawName: "v-sanitize",
              value: _vm.$t("tutorials.selector6"),
              expression: "$t('tutorials.selector6')",
            },
          ],
        }),
      ]),
      _c(
        "v-row",
        { staticClass: "my-0 mx-md-0 mx-n8 pb-0 px-0 mt-n8" },
        [
          !_vm.IsMobile
            ? _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "max-width",
                      attrs: { align: "center", justify: "space-between" },
                    },
                    _vm._l(_vm.pictosGrey, function (save, index) {
                      return _c(
                        "v-col",
                        {
                          key: index,
                          staticClass: "save-selector mb-n12 text-center",
                          class: { "selected-save": _vm.tutoIndex === index },
                          attrs: { sm: "auto" },
                          on: {
                            click: function ($event) {
                              return _vm.selectTuto(index)
                            },
                          },
                        },
                        [
                          _c("v-img", {
                            staticClass: "white--text mx-auto",
                            class: {
                              "selected-save-pic": _vm.tutoIndex === index,
                            },
                            attrs: {
                              src:
                                _vm.tutoIndex === index
                                  ? _vm.GetImgType(_vm.pictosColor[index])
                                  : _vm.GetImgType(_vm.pictosGrey[index]),
                              contain: "",
                              "aspect-ratio": 1 / 1,
                              width: 96,
                            },
                          }),
                          _c("div", { staticClass: "card-title" }, [
                            _c("h2", { staticClass: "h3 boldtitle" }, [
                              _vm._v(" " + _vm._s(_vm.tutosNames[index]) + " "),
                            ]),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-slide-group",
                    { attrs: { "show-arrows": "" } },
                    _vm._l(_vm.pictosGrey, function (save, index) {
                      return _c(
                        "v-slide-item",
                        { key: index },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mx-1",
                              attrs: {
                                "active-class": "grey white--text",
                                depressed: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTuto(index)
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "h3" }, [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.tutosNames[index])),
                                ]),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c("tutorialsBlock", { attrs: { "tuto-index": _vm.tutoIndex } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }