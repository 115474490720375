<template>
  <v-col>
    <v-row justify="center">
      <v-col v-if="!IsMobile" lg="12">
        <v-btn-toggle v-model="topicIndex" mandatory class="d-flex flex-row justify-center flex-wrap tuto-button-group">
          <v-btn v-for="(item, index) in sections" :key="index" class="" color="btn-tuto">
            <div class="">
              {{ item.title }}
            </div>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-else lg="12" class="mb-n12 mt-n8 text-center">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn class="white--text" color="cta-btn" v-on="on">
              <div v-sanitize="$t('tutorials.tuto1')" class="h3" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in sections" :key="index" @click="topicIndex = index">
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-show="topicIndex == 0" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing1')" class="tutotext" />
        </div>
        <videoBlock :src="540665387" />
        <div v-sanitize="$t('tutorials.furnishing2')" class="text-left my-12 mx-n4 mx-sm-n10 tutotext" />
        <videoBlock :src="540665246" />
      </v-col>
      <v-col v-show="topicIndex == 1" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing3')" class="tutotext" />
        </div>
        <videoBlock :src="540665351" />
      </v-col>
      <v-col v-show="topicIndex == 2" sm="10" class="text-center">
        <div v-sanitize="$t('tutorials.furnishing4')" class="text-left my-12 mx-n4 mx-sm-n10 tutotext" />
        <videoBlock :src="540665286" />
      </v-col>
      <v-col v-show="topicIndex == 3" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing5')" class="tutotext" />
        </div>
        <videoBlock :src="540665066" />
      </v-col>
      <v-col v-show="topicIndex == 4" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing6')" class="tutotext" />
        </div>
        <videoBlock :src="540665005" />
      </v-col>
      <v-col v-show="topicIndex == 5" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing7')" class="tutotext" />
        </div>
        <videoBlock :src="540665326" />
      </v-col>
      <v-col v-show="topicIndex == 6" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing8')" class="tutotext" />
        </div>
      </v-col>
      <v-col v-show="topicIndex == 7" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing9')" class="tutotext" />
        </div>
      </v-col>
      <v-col v-show="topicIndex == 8" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing10')" class="tutotext" />
        </div>
        <videoBlock :src="540665106" />
      </v-col>
      <v-col v-show="topicIndex == 9" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing11')" class="tutotext" />
        </div>
        <videoBlock :src="540665159" />
      </v-col>
      <v-col v-show="topicIndex == 10" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.furnishing12')" class="tutotext" />
        </div>
        <videoBlock :src="540665207" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import videoBlock from "@/components/layout/videoBlock";

export default {
  components: { videoBlock },

  props: {},
  data() {
    return {
      topicIndex: 0,
    };
  },
  computed: {
    IsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    sections() {
      return [
        { title: this.$t("tutorials.furnishing13") },
        { title: this.$t("tutorials.furnishing14") },
        { title: this.$t("tutorials.furnishing15") },
        { title: this.$t("tutorials.furnishing16") },
        { title: this.$t("tutorials.furnishing17") },
        { title: this.$t("tutorials.furnishing18") },
        { title: this.$t("tutorials.furnishing19") },
        { title: this.$t("tutorials.furnishing20") },
        { title: this.$t("tutorials.furnishing21") },
        { title: this.$t("tutorials.furnishing22") },
        { title: this.$t("tutorials.furnishing23") },
      ];
    },
  },
  watch: {},
  methods: {},
};
</script>

<style></style>
