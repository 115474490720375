<template>
  <div>
    <v-app-bar v-show="show" app clipped-left flat dark elevate-on-scroll class="background-color" :style="`max-width: 100vw;`" scroll-target="#content">
      <v-btn class="text--color1" :style="'position: absolute; left: 40px; top: 20px'" depressed text @click="drawer = true">
        <div>
          <v-icon size="20" class="mr-2">
            fas fa-bars
          </v-icon>
          <b> Menu</b>
        </div>
      </v-btn>
      <v-row align="center" justify="center" class="ma-0 pa-0">
        <v-col cols="12" sm="4" class="d-flex justify-center align-center inner-content ma-0 pa-0">
          <router-link to="/">
            <div v-if="isRouteUtopiq" class="ma-0 pa-0 d-flex justify-center align-center">
              <v-img class="mr-3" contain max-width="28px" :src="GetImgType('/img/logo/logo_utopiq_house_icon.png')" />
              <div class="h4 ma-0 pa-0 active" v-sanitize="$t('mainlayout.utopiqtitle')"></div>
            </div>
            <div v-else-if="isRouteVolumiq" class="ma-0 pa-0 d-flex justify-center align-center">
              <v-img class="mr-3" contain max-width="28px" :src="GetImgType('/img/logo/Volumiq_Logox64.png')" />
              <div class="h4 ma-0 pa-0 active" v-sanitize="$t('mainlayout.volumiqtitle')"></div>
            </div>
            <div v-else class="ma-0 pa-0 d-flex justify-center align-center">
              <v-img class="mr-3" contain max-width="28px" :src="GetImgType('/img/logo/logo_utopiq_house_icon.png')" />
              <v-img class="" contain max-width="28px" :src="GetImgType('/img/logo/Volumiq_Logox64.png')" />
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-navigation-drawer v-show="show" v-model="drawer" clipped app :height="`calc(100vh - ${$vuetify.application.top}px)`" :style="`margin-top: 70px`" class="background-color">
      <v-row class="d-flex flex-column align-center ma-0 justify-start" style="height: 85%">
        <v-col cols="auto" class="mt-12">
          <v-img class="float-left mt-1" :class="{ grayscale: !isRouteVolumiq }" contain max-width="28px" :src="GetImgType('/img/logo/Volumiq_Logox64.png')" />
          <v-menu offset-x content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.volumiqtitle')" class="ma-0 pa-0" :class="{ active: isRouteVolumiq }" />
                <v-icon v-if="value" right> mdi-chevron-left </v-icon>
                <v-icon v-else right> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="project.volumiqprojects" redirection="Projects" :need-logged="true" :hide-logged="false" :external-link="false" :add-divider="true" />
              <navbarRedirect text-code="mainlayout.theapp" redirection="Volumiq" :need-logged="false" :external-link="false" />
              <!-- <navbarRedirect text-code="mainlayout.features" redirection="Features" :need-logged="false" :external-link="false" /> -->
              <navbarRedirect text-code="mainlayout.tutorials" redirection="Tutorials" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="mainlayout.pricing" redirection="Pricing" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="mainlayout.blog" redirection="Blog" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="retail.b2bmaintitle" redirection="Retail" :need-logged="false" :external-link="false" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.roadmap" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.roadmap" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.changelog" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.changelog" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.integration" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.integration" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub" />
              <navbarRedirect text-code="credits.credits" redirection="Credits" :need-logged="false" :external-link="false" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="mt-3">
          <v-img class="float-left mt-1" :class="{ grayscale: !isRouteUtopiq }" contain max-width="28px" :src="GetImgType('/img/logo/logo_utopiq_house_icon.png')" />
          <v-menu offset-x content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.volumiqgames')" class="ma-0 pa-0" :class="{ active: isRouteUtopiq }" />
                <v-icon v-if="value" right> mdi-chevron-left </v-icon>
                <v-icon v-else right> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.utopiqtitle" redirection="Utopiq" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="mainlayout.neighborhoodtitle" redirection="Neighborhood" :need-logged="false" :external-link="false" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="mt-3 flex-grow-1">
          <v-menu offset-x content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.community')" class="ma-0 pa-0" />
                <v-icon v-if="value" right> mdi-chevron-left </v-icon>
                <v-icon v-else right> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.discord" :need-logged="false" :external-link="true" redirection="https://discord.gg/MN2X66RvW9" :icon="'fab fa-discord'" />
              <navbarRedirect text-code="mainlayout.twitter" :need-logged="false" :external-link="true" redirection="https://twitter.com/Utopiq_Volumiq" :icon="'fab fa-twitter'" />
              <navbarRedirect text-code="mainlayout.pinterest" :need-logged="false" :external-link="true" redirection="https://www.pinterest.fr/volumiq/" :icon="'fab fa-pinterest'" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col v-if="!isLogged" cols="auto">
          <v-dialog v-model="dialog" max-width="100vw">
            <template #activator="{ on, attrs }">
              <v-btn x-large v-bind="attrs" class="white--text mb-5 mb-sm-0 cta-btn mr-3" v-on="on">
                <div class="h2 my-2">
                  <b v-sanitize="$t('mainlayout.connect')" />
                </div>
              </v-btn>
            </template>
            <loginModal />
          </v-dialog>
        </v-col>
        <v-col v-show="isLogged" cols="auto" class="mt-3">
          <v-menu offset-x content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 class="ma-0 pa-0">
                  {{ fullname }}
                </h4>
                <v-icon v-if="value" right> mdi-chevron-left </v-icon>
                <v-icon v-else right> mdi-chevron-right </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.myaccount" redirection="Account" :need-logged="true" :hide-logged="false" :external-link="false" />
              <v-divider />
              <div class="h4 my-1 poppins" @click="logoff()">
                <a href="#">
                  <div v-sanitize="$t('mainlayout.deconnect')" class="pa-2 dropdown-item" />
                </a>
              </div>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="">
          <localeSelector class="ma-0 pa-0" />
        </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
import loginModal from "@/components/index/loginModal";
import { logoutCall } from "@/api/account";
import localeSelector from "@/components/layout/localeSelector";
import navbarRedirect from "@/components/layout/navigation/navbarRedirect";
export default {
  components: {
    loginModal,
    localeSelector,
    navbarRedirect,
  },
  props: {
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      drawer: false,
      dialog: false,
      window,
    };
  },
  computed: {
    isLogged() {
      return (
        this.$userStore.state.user != undefined &&
        Object.keys(this.$userStore.state.user).length > 0
      );
    },
    user() {
      return this.$userStore.state.user;
    },
    fullname() {
      if (this.user != null) {
        if (this.user.lastname != null) {
          return `${this.user.firstname} ${this.user.lastname}`;
        }
        return `${this.user.firstname}`;
      }
    },
    isRouteUtopiq() {
      switch (this.$route.name) {
        case "Utopiq":
          return true;
        default:
          return false;
      }
    },
    isRouteVolumiq() {
      switch (this.$route.name) {
        case "Projects":
        case "Project":
        case "Volumiq":
        case "Features":
        case "Tutorials":
        case "Pricing":
        case "Blog":
        case "BlogPost":
        case "Retail":
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    logoff() {
      logoutCall().then(() => {
        this.dialog = false;
        this.$cookies.remove("connect.sid");
        this.$router.push({ name: "Volumiq" });
      });
    },
  },
};
</script>

<style scoped>
a {
  text-transform: unset;
  color: unset !important;
}

.background-color,
.background-color>>>.v-navigation-drawer__content {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

.v-icon.v-icon::after {
  border-radius: 0% !important;
}

.theme--light.v-icon.coin {
  color: var(--v-yellow-base) !important;
}

.v-menu__content {
  box-shadow: none !important;
}

.active {
  font-family: "Inter-Bold" !important;
}

.grayscale {
  filter: grayscale(1);
}
</style>
