<template>
  <div :class="navbarClasses">
    <v-col cols="12" lg="9" xl="9" class="mx-auto pa-0" :class="{ navbarborder: scrolled }" style="height: 7vh; min-height: 64px">
      <v-row align="center" style="height: 100%; width: 100% !important;" no-gutters class="flex-nowrap ma-0 mx-2 pa-0 ">
        <v-col cols="auto" class="flex-grow-0 flex-shrink-0 mx-4">
          <v-img class="float-left mt-1 logo" :class="{ grayscale: !isRouteVolumiq }" :max-width="25" :width="25" :src="GetImgType('/img/logo/Volumiq_Logox64.png')" />
          <v-menu open-on-hover offset-y content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.volumiqtitle')" class="ma-0 pa-0" :class="{ active: isRouteVolumiq }" />
                <v-icon v-if="value" right> mdi-chevron-up </v-icon>
                <v-icon v-else right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="project.volumiqprojects" redirection="Projects" :need-logged="true" :hide-logged="false" :external-link="false" :add-divider="true" />
              <navbarRedirect text-code="mainlayout.theapp" redirection="Volumiq" :need-logged="false" :external-link="false" />
              <!-- <navbarRedirect text-code="mainlayout.features" redirection="Features" :need-logged="false" :external-link="false" /> -->
              <navbarRedirect text-code="mainlayout.tutorials" redirection="Tutorials" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="mainlayout.pricing" redirection="Pricing" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="retail.b2bmaintitle" redirection="Retail" :need-logged="false" :external-link="false" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.roadmap" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.roadmap" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.changelog" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.changelog" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub" />
              <navbarRedirect v-show="$i18n.locale != 'fr'" text-code="doc.integration" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub" />
              <navbarRedirect v-show="$i18n.locale == 'fr'" text-code="doc.integration" :need-logged="false" :external-link="true" redirection="https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub" />
              <navbarRedirect text-code="credits.credits" redirection="Credits" :need-logged="false" :external-link="false" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="flex-grow-0 flex-shrink-0 mx-4">
          <v-img class="float-left mt-1 logo" :class="{ grayscale: !isRouteUtopiq }" :max-width="25" :width="25" :src="GetImgType('/img/logo/logo_utopiq_house_icon.png')" />
          <v-menu open-on-hover offset-y content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.volumiqgames')" class="ma-0 pa-0" :class="{ active: isRouteUtopiq }" />
                <v-icon v-if="value" right> mdi-chevron-up </v-icon>
                <v-icon v-else right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.utopiqtitle" redirection="Utopiq" :need-logged="false" :external-link="false" />
              <navbarRedirect text-code="mainlayout.neighborhoodtitle" redirection="Neighborhood" :need-logged="false" :external-link="false" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="flex-shrink-0 flex-grow-1 mx-4">
          <v-menu open-on-hover offset-y content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 v-sanitize="$t('mainlayout.community')" class="ma-0 pa-0" />
                <v-icon v-if="value" right> mdi-chevron-up </v-icon>
                <v-icon v-else right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.discord" :need-logged="false" :external-link="true" redirection="https://discord.gg/MN2X66RvW9" :icon="'fab fa-discord'" />
              <navbarRedirect text-code="mainlayout.twitter" :need-logged="false" :external-link="true" redirection="https://twitter.com/Utopiq_Volumiq" :icon="'fab fa-twitter'" />
              <navbarRedirect text-code="mainlayout.pinterest" :need-logged="false" :external-link="true" redirection="https://www.pinterest.fr/volumiq/" :icon="'fab fa-pinterest'" />
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="flex-grow-0 flex-shrink-0 mx-4">
          <v-dialog v-if="!isLogged" v-model="dialog" max-width="50vw">
            <template #activator="{ on, attrs }">
              <v-btn x-large v-bind="attrs" class="white--text mb-5 mb-sm-0 cta-btn mr-3" v-on="on">
                <div class="h3">
                  <b v-sanitize="$t('mainlayout.connect')" />
                </div>
              </v-btn>
            </template>
            <loginModal />
          </v-dialog>
          <v-menu v-if="isLogged" open-on-hover offset-y content-class="box-shadow box-radius">
            <template #activator="{ on, attrs, value }">
              <v-btn depressed pla color="transparent" class="ma-0 pa-1 nopointer btn-menu" v-bind="attrs" v-on="on">
                <h4 class="ma-0 pa-0">
                  {{ fullname }}
                </h4>
                <v-icon v-if="value" right> mdi-chevron-up </v-icon>
                <v-icon v-else right> mdi-chevron-down </v-icon>
              </v-btn>
            </template>
            <v-list class="dropdown pa-2">
              <navbarRedirect text-code="mainlayout.myaccount" redirection="Account" :need-logged="true" :hide-logged="false" :external-link="false" />
              <v-divider />
              <div class="h4 my-1 poppins" @click="logoff()">
                <a href="#">
                  <div v-sanitize="$t('mainlayout.deconnect')" class="pa-2 dropdown-item" />
                </a>
              </div>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="auto" class="flex-grow-0 flex-shrink-0 mx-4">
          <localeSelector />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="showNews" cols="12" class="news-area d-flex justify-center align-center" style="height: 3vh">
      <div class="h4 ma-n2 pa-n2 black--text text-center">
        {{ $t("mainlayout.news") }}
        <a @click="window.open('https://twitter.com/Utopiq_Volumiq', '_blank', 'noopener')">
          <b v-sanitize="$t('mainlayout.twitter')" />
          <v-icon class="ml-1 mt-n1" x-small>fas fa-external-link-alt</v-icon>
        </a>
        {{ $t("mainlayout.news2") }}
      </div>
    </v-col>
  </div>
</template>

<script>
import loginModal from "@/components/index/loginModal";
import localeSelector from "@/components/layout/localeSelector";
import { logoutCall } from "@/api/account";
import navbarRedirect from "@/components/layout/navigation/navbarRedirect";
export default {
  components: {
    loginModal,
    localeSelector,
    navbarRedirect,
  },
  props: {
    dark: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    showNews: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
    },
    scrolled: {
      type: Boolean,
    },
  },
  data() {
    return {
      dialog: false,
      window,
    };
  },
  computed: {
    computeLogoSize() {
      if (!this.small) {
        return 45;
      }
      return 35;
    },
    mainCoins() {
      return this.$userStore.state.user.mainCoins;
    },
    navbarClasses() {
      let str = "";
      str += " background-color";
      str += " navbar-area";
      if (this.fixed) str += " absolute-fixed";
      return str;
    },
    isLogged() {
      return (
        this.$userStore.state.user != undefined &&
        Object.keys(this.$userStore.state.user).length > 0
      );
    },
    user() {
      return this.$userStore.state.user;
    },
    fullname() {
      if (this.user != null) {
        if (this.user.lastname != null) {
          return `${this.user.firstname} ${this.user.lastname}`;
        }
        return `${this.user.firstname}`;
      }
    },
    isRouteUtopiq() {
      switch (this.$route.name) {
        case "Utopiq":
          return true;
        default:
          return false;
      }
    },
    isRouteVolumiq() {
      switch (this.$route.name) {
        case "Projects":
        case "Project":
        case "Volumiq":
        case "Tutorials":
        case "Features":
        case "Pricing":
        case "Blog":
        case "BlogPost":
        case "Retail":
          return true;
        default:
          return false;
      }
    },
  },
  watch: {},
  mounted() {
    this.$root.$on("set-navbar-small", this.setNavbarSmall);
    this.$root.$on("set-navbar-showNews", this.setNavbarShowNews);
    this.$root.$on("set-navbar-dark", this.setNavbarDark);
    this.$root.$on("set-navbar-scrolled", this.setNavbarScrolled);
  },
  beforeDestroy() {
    this.$root.$off("set-navbar-small", this.setNavbarSmall);
    this.$root.$off("set-navbar-showNews", this.setNavbarShowNews);
    this.$root.$off("set-navbar-dark", this.setNavbarDark);
    this.$root.$off("set-navbar-scrolled", this.setNavbarScrolled);
  },
  created() { },
  destroyed() { },
  methods: {
    logoff() {
      logoutCall().then(() => {
        this.dialog = false;
        this.$cookies.remove("connect.sid");
        this.$router.push({ name: "Volumiq" });
      });
    },
    setNavbarSmall(a) {
      this.small = a;
    },
    setNavbarShowNews(a) {
      this.showNews = a;
    },
    setNavbarDark(a) {
      this.dark = a;
    },
    setNavbarScrolled(a) {
      this.scrolled = a;
    },
  },
};
</script>

<style scoped>
.theme--light.v-icon.coin {
  color: var(--v-yellow-base) !important;
}

.navbar-area {
  min-height: 64px;
  max-height: 14vh;
  overflow-x: hidden;
  overflow-y: hidden;

  z-index: 1;
}

.background-color {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

.background-transparent {
  background-color: transparent !important;
  color: var(--text-color) !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
}

.navbarborder {
  border-bottom: 1px solid var(--divider-color);
}

.news-area {
  min-height: 3vh;
  background-color: var(--v-lightBlue-base);
}

.fixed-navbar {
  position: fixed !important;
  top: 0 !important;
  z-index: 200;
}

.round {
  border-radius: 25% !important;
}

.inner-content {
  height: 14vh;
}

a {
  text-transform: unset;
  color: unset !important;
}

.theme--light.v-icon {
  color: unset !important;
}

.absolute-fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0% !important;
  margin-bottom: 0% !important;
}

.h1 {
  font-size: 26px !important;
}

.h3 {
  font-size: 18px !important;
}

.v-application .v-menu__content {
  box-shadow: none;
}

.nopointer {
  cursor: auto;
}

.active {
  font-family: "Inter-Bold" !important;
}

.grayscale {
  filter: grayscale(1);
}

.logo {
  height: 25px !important;
  width: 25px !important;
}
</style>
