<template>
  <div>
    <v-col cols="12" class="background-color navbar-area-sm ma-0 pa-0 elevation-3">
      <v-row align="center" justify="space-around" class="ma-0 pa-0 mb-">
        <v-col cols="12" sm="auto" class="
            d-flex
            flex-column
            justify-center
            flex-sm-row
            align-center
            inner-content
          ">
          <div class="h2 utopiq-logo titre d-inline-flex align-center">
            <v-img class="mr-1" contain max-width="28px" :src="GetImgType('/img/logo/Volumiq_Logox64.png')" />
            <h4 class="ma-0 pa-0">Volumiq design system</h4>
          </div>
        </v-col>
        <v-col cols="12" sm="auto" class="
            d-flex
            flex-column
            justify-space-around
            flex-sm-row
            align-center
          ">
          <v-row v-if="isLogged" align="center" justify="center" class="text-center">
            <v-btn v-sanitize="$t('project.volumiqprojects')" depressed pla color="transparent" class="h4 my-2 pointer btn-menu" @click="redirectTo({ name: 'Projects' })" />
            <v-icon class="mx-3"> mdi-chevron-right </v-icon>
            <v-btn depressed pla color="transparent" class="h4 my-2 pointer btn-menu" @click="redirectTo(redirectProjet)">
              {{ name }}
            </v-btn>
          </v-row>
          <v-row v-else align="center" justify="center" class="text-center">
            <v-btn v-sanitize="$t('mainlayout.returnhome')" depressed pla color="transparent" class="h4 my-2 pointer btn-menu" @click="redirectTo({ name: 'Volumiq' })" />
          </v-row>
        </v-col>
        <v-col cols="12" sm="auto" class="d-flex flex-column justify-start flex-sm-row align-center">
          <div class="mx-4">
            <v-dialog v-if="pinterest" width="70vw" min-height="70vh" content-class="round">
              <template #activator="{ on, attrs }">
                <div class="h4 mx-12">
                  <a v-sanitize="$t('project.mypinterestboard')" href="#" v-bind="attrs" v-on="on" @click="reloadPinterest" />
                </div>
              </template>
              <template>
                <v-card class="pa-6 text-center scroll" style="height: 75vh">
                  <div class="h4 boldtitle mr-5 mt-0">
                    {{ $t("project.mypinterestboard") }}
                    <a href="#" @click="window.open(pinterest, '_blank', 'noopener')">
                      <v-icon class="black--text mx-3 mt-n2">fas fa-external-link-alt
                      </v-icon>
                    </a>
                  </div>
                  <div class="pincontainer">
                    <a data-pin-do="embedBoard" data-pin-lang="fr" :href="pinterest" data-pin-scale-height="640" />
                  </div>
                </v-card>
              </template>
            </v-dialog>
          </div>
          <div class="mx-2">
            <v-dialog max-width="80vw" content-class="round">
              <template #activator="{ on, attrs }">
                <div class="h4 mx-12">
                  <a href="#" v-bind="attrs" v-on="on">
                    {{ $t("mainlayout.guide") }}
                    <v-icon class="mt-n1">fas fa-info-circle </v-icon>
                  </a>
                </div>
              </template>
              <template>
                <v-card class="tutodialog scroll pa-12">
                  <tutorialSelector class="pa-12 mb-12" />
                </v-card>
              </template>
            </v-dialog>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import { getProject } from "@/api/project";
import tutorialSelector from "@/components/tutorials/tutorialSelector";
export default {
  components: {
    tutorialSelector,
  },

  props: {},
  data() {
    return {
      window,

      id: null,
      name: "",
      type: "",
      pinterest: "",
      dialog: true,
    };
  },
  computed: {
    mainCoins() {
      return this.$userStore.state.user.mainCoins;
    },
    redirectProjet() {
      return {
        name: "Project",
        params: { projectid: this.id },
      };
    },
    isLogged() {
      return (
        this.$userStore.state.user != undefined &&
        Object.keys(this.$userStore.state.user).length > 0
      );
    },
  },
  watch: {
    pinterest(a) {
      this.$root.$on("set-config-navbar-pinterest", a);
    },
  },
  mounted() {
    if (window.doBuildPinterest) window.doBuildPinterest();
  },
  beforeDestroy() {
    this.$root.$off("set-config-navbar-projectid", this.setProjectId);
    this.$root.$off("set-config-navbar-pinterest", this.setProjectPin);
  },
  created() {
    this.$root.$on("set-config-navbar-projectid", this.setProjectId);
    this.$root.$on("set-config-navbar-pinterest", this.setProjectPin);
  },
  destroyed() { },
  methods: {
    redirectTo(route) {
      if (window.gameInstance)
        window.gameInstance.Quit().then(() => {
          window.UnityProgressInit = null;
          window.UnityProgressUpdate = null;
          window.UnityProgress = null;
          window.UnityProgressEnd = null;
          window.appelFunction = null;
          window.PluginSay = null;
          window.pluginAwake = null;
          window.pluginLoading = null;
          window.pluginLoaded = null;
          window.setRemoteRendererSrvUrl = null;
          window.setAPISrvUrl = null;
          window.setAllowRemoteRendering = null;
          window.redirect = null;
          window.setLanguage = null;
          window.sendBrand = null;
          window.sendMode = null;
          window.sendServer = null;
          window.setRendererUseHttp = null;
          window.setDisableSaves = null;
          window.sendSession = null;
          window.sendSessionFromJson = null;
          window.PluginValidateReturnSceneKey = null;
          window.PluginPrintScreenshot = null;
          window.PluginGetServer = null;
          window.PluginValidateScene = null;
          window.sendInformationToPluginLoading = null;
          window.sendInformationToPluginLoaded = null;
          window.gameInstance = null;
          this.$router.push(route);
        });
      else this.$router.push(route);
    },
    setProjectName(name) {
      this.name = name;
    },
    setProjectPin(pin) {
      this.pinterest = pin;
      this.reloadPinterest();
    },
    setProjectId(id) {
      this.id = id;
      this.getProjectData();
    },
    getProjectData() {
      getProject(this.id).then((res) => {
        this.setProjectName(res.name);
      });
    },
    reloadPinterest() {
      setTimeout(() => {
        if (window.doBuildPinterest) window.doBuildPinterest();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.theme--light.v-icon.coin {
  color: var(--v-yellow-base) !important;
}

.navbar-area {
  min-height: 14vh;
  overflow-x: hidden;
  overflow-y: hidden;
}

.background-color,
.tutodialog {
  background-color: var(--bg-color) !important;
  color: var(--text-color) !important;
}

.white-bg {
  background-color: var(--v-white-base) !important;
}

.news-area {
  min-height: 4vh;
  background-color: var(--v-lightBlue-base);
}

.fixed-navbar {
  position: fixed !important;
  top: 0 !important;
  z-index: 200;
}

.altern-eight {
  background: linear-gradient(0deg, #4bbf3a, #90d955);
  background-position: 100%;
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.navbar-tall {
  min-height: 18vh;
}

.navbar-area-sm {
  min-height: 6vh;
  z-index: 1;
}

.d-flex,
.navbar-area-sm h1,
.navbar-area-sm h2,
.navbar-area-sm h3 {
  padding: 0px !important;
  margin: 0px !important;
}

.navbar-area-sm .mx-2 {
  margin-right: 4px !important;
  margin-left: 4px !important;
}

.navbar-area-sm .titre .ml-6 {
  margin-left: 12px !important;
}

.navbar-area-sm .inner-content {
  height: 6vh;
}

.inner-content {
  height: 14vh;
}

a {
  text-transform: unset;
  color: unset !important;
}

.theme--light.v-icon {
  color: unset !important;
}

.absolute-fixed {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 100 !important;
}

.scroll {
  max-height: 80vh;
  overflow-y: scroll;
}

.v-dialog .round .v-dialog--active {
  border-radius: 4rem !important;
  overflow-y: hidden;
}

.pincontainer {
  width: 100%;
  height: 100%;
}

.pincontainer>>>span {
  width: 100%;

  max-height: 90% !important;
  max-width: unset !important;
}

.pointer {
  cursor: pointer;
}
</style>
