<template>
  <v-col cols="12" class="images-block mt-3 pt-n5 box-radius" :class="{
    'images-block-right-corner': !IsMobile && tutoIndex === 4,
    'images-block-left-corner': !IsMobile && tutoIndex === 0,
  }">
    <v-row class="mt-n5 mb-10 inside">
      <tutoBlockRoomBuilding v-show="tutoIndex === 0" />
      <tutoBlockFurnishing v-show="tutoIndex === 1" />
      <tutoBlockDeco v-show="tutoIndex === 2" />
      <tutoBlockExperience v-show="tutoIndex === 3" />
      <tutoBlockFinalizing v-show="tutoIndex === 4" />
    </v-row>
  </v-col>
</template>

<script>
import tutoBlockDeco from "@/components/tutorials/tutoBlockDeco";
import tutoBlockRoomBuilding from "@/components/tutorials/tutoBlockRoomBuilding";
import tutoBlockFurnishing from "@/components/tutorials/tutoBlockFurnishing";
import tutoBlockExperience from "@/components/tutorials/tutoBlockExperience";
import tutoBlockFinalizing from "@/components/tutorials/tutoBlockFinalizing";

export default {
  components: {
    tutoBlockFinalizing,
    tutoBlockExperience,
    tutoBlockFurnishing,
    tutoBlockRoomBuilding,
    tutoBlockDeco,
  },
  props: {
    tutoIndex: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    IsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.images-block-left-corner {
  border-top-left-radius: 0rem !important;
}

.images-block-right-corner {
  border-top-right-radius: 0rem !important;
}

.images-block:not(.images-block-right-corner, .images-block-left-corner) {
  /* background-color: var(--v-lighterBlue-base) !important; */
  background-color: white !important;
  padding: 4rem;
}

.images-block {
  /* background-color: var(--v-lighterBlue-base) !important; */
  background-color: white !important;
  padding: 4rem;
}

.selected-save-pic {
  border: solid #fabe69 !important;
  border-width: 0.5rem !important;
}

.save-selector {
  padding: 2rem;
}

.long-button h3 {
  margin: 0 !important;
  text-transform: none;
}

.inside {
  min-height: 372px;
}
</style>

<style></style>
