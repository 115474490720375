import { fetch } from '@/tools/axios'

const api = process.env.VUE_APP_API_URL

export const createProject = (body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		body
	}
	return fetch(`${api}/project/create`, options)
}

export const getProjects = () => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/project/list`, options)
}

export const getProject = (param) => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/project/${param}`, options)
}

export const getPublicProject = (param) => {
	const options = {
		method: 'GET',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/project/public/${param}`, options)
}

export const deleteProject = (param) => {
	const options = {
		method: 'DELETE',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store'
	}
	return fetch(`${api}/project/delete/${param}`, options)
}

export const editProject = (id, body) => {
	const options = {
		method: 'PUT',
		credentials: 'include',
		withCredentials: true,
		cache: 'no-store',
		body
	}
	return fetch(`${api}/project/edit/${id}`, options)
}

export const deleteSceneKey = (id, body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		body
	}
	return fetch(`${api}/project/${id}/deleteSceneKey`, options)
}

export const editSceneKeyProject = (id, body) => {
	const options = {
		method: 'POST',
		credentials: 'include',
		withCredentials: true,
		body
	}
	return fetch(`${api}/project/${id}/editSceneKeyProject`, options)
}

export const testCartAvailable = (body) => {
	const options = {
	  method: "POST",
	  credentials: "include",
	  withCredentials: true,
	  cache: "no-store",
	  body
	};
	return fetch(`${api}/project/testCartAvailable`, options);
  };
  
  export const testExportAvailable = (body) => {
	const options = {
	  method: "POST",
	  credentials: "include",
	  withCredentials: true,
	  cache: "no-store",
	  body
	};
	return fetch(`${api}/project/testExportAvailable`, options);
  };
  