module.exports = [
  {
    path: "",
    name: "Volumiq",
    component: () => import("../views/other/retail.vue"),
    meta: {
      title: "Volumiq",
    },
    sitemap: { priority: 1 },
  },
  {
    path: "utopiq",
    name: "Utopiq",
    component: () => import("../views/utopiq.vue"),
    meta: {
      title: "Utopiq & Volumiq. Build virtual spaces.",
    },
    sitemap: { priority: 0.9 },
  },
  {
    path: "neighborhood",
    name: "Neighborhood",
    component: () => import("../views/neighborhood.vue"),
    meta: {
      title: "Utopiq & Volumiq. Build virtual spaces.",
    },
    sitemap: { priority: 0.9 },
  },
  {
    path: "account/agreement",
    name: "Agreement",
    component: () => import("../views/account/agreement.vue"),
    meta: {
      title: "Agreement",
      requiresAuth: true,
    },
  },
  {
    path: "projects",
    name: "Projects",
    component: () => import("../views/account/projects.vue"),
    meta: {
      requiresAuth: true,
      title: "Projects",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "tutorials",
    name: "Tutorials",
    component: () => import("../views/tutorials.vue"),
    meta: {
      title: "Tutorials",
    },
    sitemap: { priority: 0.7 },
  },
  {
    path: "termsandconditions",
    name: "TermsAndConditions",
    component: () => import("../views/other/termsandconditions.vue"),
    meta: {
      title: "Terms And Conditions",
    },
  },
  {
    path: "project/:projectid",
    name: "Project",
    component: () => import("../views/account/project.vue"),
    meta: {
      title: "Project",
      requiresAuth: true,
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "project/public/:id",
    name: "SharedProject",
    component: () => import("../views/account/sharedproject.vue"),
    meta: {
      title: "Shared Project",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "configurator",
    name: "Configurator",
    component: () => import("../views/configurator.vue"),
    props: true,
    meta: {
      title: "Configurator",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "account",
    name: "Account",
    component: () => import("../views/account/settings.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      title: "Account",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "pricing",
    name: "Pricing",
    component: () => import("../views/account/coinshop.vue"),
    props: true,
    meta: {
      title: "Pricing",
      requiresAuth: false,
    },
  },
  {
    path: "documentation",
    name: "Documentation",
    component: () => import("../views/other/documentation.vue"),
    meta: {
      title: "Documentation",
    },
    sitemap: { priority: 0.8 },
  },
  {
    path: "purchaseconfirmed",
    name: "PurcheseConfirmed",
    component: () => import("../views/other/purchasecomplete.vue"),
    props: true,
    meta: {
      title: "Purchase Confirmed",
      requiresAuth: true,
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "account-activation",
    name: "AccountActivation",
    component: () => import("../views/account/accountactivation.vue"),
    meta: {
      title: "Account Activation",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "password-reset",
    name: "PasswordReset",
    component: () => import("../views/account/lostpassword.vue"),
    meta: {
      title: "Password Reset",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "blog",
    name: "Blog",
    component: () => import("../views/blog/blog.vue"),
    meta: {
      title: "Blog",
      sitemap: { priority: 0.8 },
    },
  },
  {
    path: "blog/:id",
    name: "BlogPost",
    component: () => import("../views/blog/post.vue"),
    meta: {
      title: "BlogPost"
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "b2b",
    name: "Retail",
    component: () => import("../views/other/b2b.vue"),
    meta: {
      title: "Retail",
    },
    sitemap: { priority: 0.9 },
  },
  // {
  //   path: "features",
  //   name: "Features",
  //   component: () => import("../views/features.vue"),
  //   meta: {
  //     title: "Features",
  //   },
  //   sitemap: { priority: 0.9 },
  // },
  {
    path: "credits",
    name: "Credits",
    component: () => import("../views/other/credits.vue"),
    meta: {
      title: "Credits",
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "software/builds/WLU/:id",
    name: "WhatLiesUnder",
    component: () => import("../views/other/WLU.vue"),
    meta: {
      title: "WhatLiesUnder",
    },
    sitemap: { ignoreRoute: true },
    props: true
  },
  {
    path: "admin",
    name: "Admin",
    component: () => import("../views/other/admin.vue"),
    meta: {
      title: "Admin",
      requiresAuth: true,
    },
    sitemap: { ignoreRoute: true },
  },
  {
    path: "*",
    name: "404",
    component: () => import("../views/other/404.vue"),
    meta: {
      title: "404",
    },
    sitemap: { ignoreRoute: true },
  },
];
