var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup border-radius" },
    [
      _c(
        "v-row",
        {
          staticClass: "poprows text-center ma-0 py-12 pa-sm-12 pb-7",
          attrs: { align: "start", justify: "center" },
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column align-start",
              attrs: { cols: "6" },
            },
            [
              _c("v-col", { attrs: { cols: "12" } }, [
                _c("div", { staticClass: "h2" }, [
                  _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("mainlayout.connect"),
                        expression: "$t('mainlayout.connect')",
                      },
                    ],
                  }),
                ]),
              ]),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-text-field", {
                    attrs: {
                      label: _vm.$t("account.email"),
                      outlined: "",
                      dense: "",
                      rules: [
                        (v) => !!v || _vm.$t("account.accountlogin05"),
                        (v) =>
                          /.+@.+/.test(v) || _vm.$t("account.accountlogin04"),
                      ],
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.signupForm,
                        expression: "signupForm",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("account.firstname"),
                      outlined: "",
                      dense: "",
                      rules: [(v) => !!v || _vm.$t("account.accountlogin05")],
                    },
                    model: {
                      value: _vm.firstName,
                      callback: function ($$v) {
                        _vm.firstName = $$v
                      },
                      expression: "firstName",
                    },
                  }),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.signupForm,
                        expression: "signupForm",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("account.lastname"),
                      outlined: "",
                      dense: "",
                      rules: [(v) => !!v || _vm.$t("account.accountlogin05")],
                    },
                    model: {
                      value: _vm.lastName,
                      callback: function ($$v) {
                        _vm.lastName = $$v
                      },
                      expression: "lastName",
                    },
                  }),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.passlostForm,
                        expression: "!passlostForm",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("account.password"),
                      outlined: "",
                      dense: "",
                      "append-icon": _vm.showPass1 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass1 ? "text" : "password",
                      rules: [
                        (v) => !!v || _vm.$t("account.accountlogin06"),
                        (v) =>
                          (v != null && v.length >= 8) ||
                          _vm.$t("account.accountlogin01"),
                        (v) =>
                          /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
                            v
                          ) || _vm.$t("account.accountlogin03"),
                      ],
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass1 = !_vm.showPass1
                      },
                    },
                    model: {
                      value: _vm.password1,
                      callback: function ($$v) {
                        _vm.password1 = $$v
                      },
                      expression: "password1",
                    },
                  }),
                  _c("v-text-field", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.signupForm,
                        expression: "signupForm",
                      },
                    ],
                    attrs: {
                      label: _vm.$t("account.accountlogin18"),
                      outlined: "",
                      dense: "",
                      "append-icon": _vm.showPass2 ? "mdi-eye" : "mdi-eye-off",
                      type: _vm.showPass2 ? "text" : "password",
                      rules: [
                        (v) => !!v || _vm.$t("account.accountlogin05"),
                        (v) =>
                          v === _vm.password1 ||
                          _vm.$t("account.accountlogin02"),
                      ],
                    },
                    on: {
                      "click:append": function ($event) {
                        _vm.showPass2 = !_vm.showPass2
                      },
                    },
                    model: {
                      value: _vm.password2,
                      callback: function ($$v) {
                        _vm.password2 = $$v
                      },
                      expression: "password2",
                    },
                  }),
                  _c(
                    "div",
                    [
                      _c("v-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.signupForm && !_vm.passlostForm,
                            expression: "!signupForm && !passlostForm",
                          },
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("account.accountlogin07"),
                            expression: "$t('account.accountlogin07')",
                          },
                        ],
                        staticClass: "white--text mb-5 mb-sm-0 cta-btn mr-3",
                        attrs: { disabled: _vm.submitEnabled },
                        on: {
                          click: function ($event) {
                            return _vm.connect()
                          },
                        },
                      }),
                      _c("v-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.signupForm || _vm.passlostForm,
                            expression: "signupForm || passlostForm",
                          },
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("global.back"),
                            expression: "$t('global.back')",
                          },
                        ],
                        attrs: { dense: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.signupForm = false
                            _vm.passlostForm = false
                          },
                        },
                      }),
                      _c("v-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.signupForm && !_vm.passlostForm,
                            expression: "!signupForm && !passlostForm",
                          },
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("account.accountlogin10"),
                            expression: "$t('account.accountlogin10')",
                          },
                        ],
                        attrs: { dense: "", outlined: "" },
                        on: {
                          click: function ($event) {
                            _vm.signupForm = true
                          },
                        },
                      }),
                      _c("v-btn", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.signupForm || _vm.passlostForm,
                            expression: "signupForm || passlostForm",
                          },
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("account.accountlogin08"),
                            expression: "$t('account.accountlogin08')",
                          },
                        ],
                        staticClass: "white--text mb-5 mb-sm-0 cta-btn mr-3",
                        attrs: { disabled: _vm.submitEnabled },
                        on: {
                          click: function ($event) {
                            return _vm.submit()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.signupForm && !_vm.passlostForm,
                        expression: "!signupForm && !passlostForm",
                      },
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("account.accountlogin09"),
                        expression: "$t('account.accountlogin09')",
                      },
                    ],
                    attrs: { "x-small": "", color: "white", elevation: "0" },
                    on: {
                      click: function ($event) {
                        _vm.passlostForm = true
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "loginBtn",
                      attrs: { outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loginPopupG()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "loginBtnInside d-flex justify-start" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(" fab fa-google-plus "),
                          ]),
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("mainlayout.connectwithgoogle"),
                                expression:
                                  "$t('mainlayout.connectwithgoogle')",
                              },
                            ],
                            staticClass: "h4",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "loginBtn",
                      attrs: { outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loginLinkedin()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "loginBtnInside d-flex justify-start" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(" fab fa-linkedin-in "),
                          ]),
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("mainlayout.connectwithlnkdn"),
                                expression: "$t('mainlayout.connectwithlnkdn')",
                              },
                            ],
                            staticClass: "h4",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "loginBtn",
                      attrs: { outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loginTwitter()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "loginBtnInside d-flex justify-start" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(" fab fa-twitter "),
                          ]),
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("mainlayout.connectwithtwitter"),
                                expression:
                                  "$t('mainlayout.connectwithtwitter')",
                              },
                            ],
                            staticClass: "h4",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "loginBtn",
                      attrs: { outlined: "", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.loginPopupFB()
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "loginBtnInside d-flex justify-start" },
                        [
                          _c("v-icon", { staticClass: "mr-3" }, [
                            _vm._v(" fab fa-facebook "),
                          ]),
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("mainlayout.connectwithfb"),
                                expression: "$t('mainlayout.connectwithfb')",
                              },
                            ],
                            staticClass: "h4",
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-snackbar",
        {
          attrs: { timeout: 1000 },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [_vm._v(" " + _vm._s(_vm.snackbartext) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }