import en from './gb.json'
import fr from './fr.json'

export const defaultLocale = 'fr'

export const languages = {
	en,
	fr
}

export function supportedLocalesInclude(locale) {
	return Object.keys(languages).includes(locale)
}
