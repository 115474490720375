var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            "close-on-content-click": false,
            "content-class": "box-shadow",
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "pa-2 px-3 btn-menu",
                          attrs: {
                            depressed: "",
                            pla: "",
                            color: "transparent",
                            "aria-label": _vm.$t("meta-alt.localeselectorbtn"),
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c("gb-flag", {
                        staticClass: "ma-0 pa-0 pt-n5",
                        staticStyle: { width: "40px" },
                        attrs: { code: _vm.langs[_vm.selectedLang] },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "v-list",
            { staticClass: "dropdown pa-2", attrs: { width: "200px" } },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-row ma-0 pa-0",
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c("v-col", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "mainlayout.language",
                            expression: "'mainlayout.language'",
                          },
                        ],
                        staticClass: "ma-0 pa-0 h4 my-1 poppins",
                      }),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "content-class": "box-shadow",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "nopointer btn-menu",
                                              attrs: {
                                                depressed: "",
                                                pla: "",
                                                color: "transparent",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("gb-flag", {
                                            staticStyle: { width: "40px" },
                                            attrs: {
                                              code: _vm.langs[_vm.selectedLang],
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "dropdown pa-2" },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { mandatory: "" },
                                      model: {
                                        value: _vm.selectedLang,
                                        callback: function ($$v) {
                                          _vm.selectedLang = $$v
                                        },
                                        expression: "selectedLang",
                                      },
                                    },
                                    _vm._l(_vm.langs, function (item, index) {
                                      return _c(
                                        "v-list-item",
                                        {
                                          key: index,
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeLanguage(index)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "v-list-item-title",
                                            [
                                              _c("gb-flag", {
                                                staticStyle: { width: "40px" },
                                                attrs: { code: item },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-item",
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "flex-row ma-0 pa-0",
                      attrs: { justify: "center", align: "center" },
                    },
                    [
                      _c("v-col", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "mainlayout.region",
                            expression: "'mainlayout.region'",
                          },
                        ],
                        staticClass: "ma-0 pa-0 h4 my-1 poppins",
                      }),
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "offset-y": "",
                                "content-class": "box-shadow",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "nopointer btn-menu",
                                              attrs: {
                                                depressed: "",
                                                pla: "",
                                                color: "transparent",
                                              },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "h3 black--text" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.regions[
                                                      _vm.selectedRegion
                                                    ]
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "dropdown pa-2" },
                                [
                                  _c(
                                    "v-list-item-group",
                                    {
                                      attrs: { mandatory: "" },
                                      model: {
                                        value: _vm.selectedRegion,
                                        callback: function ($$v) {
                                          _vm.selectedRegion = $$v
                                        },
                                        expression: "selectedRegion",
                                      },
                                    },
                                    _vm._l(_vm.regions, function (item, index) {
                                      return _c(
                                        "v-list-item",
                                        { key: index },
                                        [
                                          _c("v-list-item-title", [
                                            _c("div", { staticClass: "h4" }, [
                                              _vm._v(" " + _vm._s(item) + " "),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }