<template>
  <v-card class="popup">
    <v-row class="poprows text-center ma-0 pa-12 pb-7" align="center" justify="center">
      <v-col cols="12">
        <div class="h2">
          <b v-if="!isReferral && isBonus" v-sanitize="$t('coins.welcome')" />
          <b v-else v-sanitize="$t('coins.congrat')" />
        </div>
      </v-col>
      <v-col cols="12" class="mt-n5">
        <div class="h3">
          <b v-sanitize="$t('coins.youvereceived')" />
          <b> {{ nb }} </b>
          <b v-if="!isBonus && !isReferral" v-sanitize="$t('coins.freemaincoins')" />
          <b v-if="isBonus" v-sanitize="$t('coins.bonusmaincoins')" />
        </div>
        <div class="h3">
          <b v-if="isBonus && !isReferral" v-sanitize="$t('coins.withyourpurchase')" />
          <b v-if="isReferral" v-sanitize="$t('coins.referralmaincoins')" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    nb: Number,
    isBonus: Boolean,
    isReferral: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>
