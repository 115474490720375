var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "popup" },
    [
      _c(
        "v-row",
        {
          staticClass: "poprows text-center ma-0 pa-12 pb-7",
          attrs: { align: "center", justify: "center" },
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "h2" }, [
              !_vm.isReferral && _vm.isBonus
                ? _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.welcome"),
                        expression: "$t('coins.welcome')",
                      },
                    ],
                  })
                : _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.congrat"),
                        expression: "$t('coins.congrat')",
                      },
                    ],
                  }),
            ]),
          ]),
          _c("v-col", { staticClass: "mt-n5", attrs: { cols: "12" } }, [
            _c("div", { staticClass: "h3" }, [
              _c("b", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("coins.youvereceived"),
                    expression: "$t('coins.youvereceived')",
                  },
                ],
              }),
              _c("b", [_vm._v(" " + _vm._s(_vm.nb) + " ")]),
              !_vm.isBonus && !_vm.isReferral
                ? _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.freemaincoins"),
                        expression: "$t('coins.freemaincoins')",
                      },
                    ],
                  })
                : _vm._e(),
              _vm.isBonus
                ? _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.bonusmaincoins"),
                        expression: "$t('coins.bonusmaincoins')",
                      },
                    ],
                  })
                : _vm._e(),
            ]),
            _c("div", { staticClass: "h3" }, [
              _vm.isBonus && !_vm.isReferral
                ? _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.withyourpurchase"),
                        expression: "$t('coins.withyourpurchase')",
                      },
                    ],
                  })
                : _vm._e(),
              _vm.isReferral
                ? _c("b", {
                    directives: [
                      {
                        name: "sanitize",
                        rawName: "v-sanitize",
                        value: _vm.$t("coins.referralmaincoins"),
                        expression: "$t('coins.referralmaincoins')",
                      },
                    ],
                  })
                : _vm._e(),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }