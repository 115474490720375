<template>
  <v-card class="popup border-radius">
    <v-row class="poprows text-center ma-0 py-12 pa-sm-12 pb-7" align="start" justify="center">
      <v-col cols="6" class="d-flex flex-column align-start">
        <v-col cols="12">
          <div class="h2">
            <b v-sanitize="$t('mainlayout.connect')" />
          </div>
        </v-col>
        <v-col cols="12">
          <v-text-field :label="$t('account.email')" v-model="email" outlined dense :rules="[v => !!v ||  $t('account.accountlogin05'), v => /.+@.+/.test(v) || $t('account.accountlogin04')]"></v-text-field>

          <v-text-field v-show="signupForm" :label="$t('account.firstname')" v-model="firstName" outlined dense :rules="[v => !!v ||  $t('account.accountlogin05')]">
          </v-text-field>
          <v-text-field v-show="signupForm" :label="$t('account.lastname')" v-model="lastName" outlined dense :rules="[v => !!v ||  $t('account.accountlogin05')]">
          </v-text-field>

          <v-text-field v-show="!passlostForm" :label="$t('account.password')" v-model="password1" outlined dense :append-icon="showPass1 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass1 ? 'text' : 'password'" @click:append="showPass1 = !showPass1" :rules="[v => !!v ||  $t('account.accountlogin06'), v => ( v != null && v.length >= 8) || $t('account.accountlogin01'), v => /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(v) || $t('account.accountlogin03')]">
          </v-text-field>
          <v-text-field v-show="signupForm" :label="$t('account.accountlogin18')" v-model="password2" outlined dense :append-icon="showPass2 ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass2 ? 'text' : 'password'" @click:append="showPass2 = !showPass2" :rules="[v => !!v ||  $t('account.accountlogin05'), v => v === password1 || $t('account.accountlogin02')]">
          </v-text-field>
          <div>
            <v-btn :disabled="submitEnabled" v-show="!signupForm && !passlostForm" @click="connect()" class="white--text mb-5 mb-sm-0 cta-btn mr-3" v-sanitize="$t('account.accountlogin07')"></v-btn>
            <v-btn v-show="signupForm || passlostForm" @click="
              signupForm = false;
            passlostForm = false;
            " dense outlined v-sanitize="$t('global.back')"></v-btn>
            <v-btn v-show="!signupForm && !passlostForm" dense outlined @click="signupForm = true" v-sanitize="$t('account.accountlogin10')"></v-btn>
            <v-btn :disabled="submitEnabled" v-show="signupForm || passlostForm" @click="submit()" class="white--text mb-5 mb-sm-0 cta-btn mr-3" v-sanitize="$t('account.accountlogin08')"></v-btn>
          </div>
          <v-btn x-small color="white" elevation="0" v-show="!signupForm && !passlostForm" @click="passlostForm = true" v-sanitize="$t('account.accountlogin09')"></v-btn>
        </v-col></v-col>
      <v-col cols="6">
        <v-col cols="12">
          <v-btn outlined block class="loginBtn" @click="loginPopupG()">
            <div class="loginBtnInside d-flex justify-start">
              <v-icon class="mr-3"> fab fa-google-plus </v-icon>
              <div v-sanitize="$t('mainlayout.connectwithgoogle')" class="h4" />
            </div>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn outlined block class="loginBtn" @click="loginLinkedin()">
            <div class="loginBtnInside d-flex justify-start">
              <v-icon class="mr-3"> fab fa-linkedin-in </v-icon>
              <div v-sanitize="$t('mainlayout.connectwithlnkdn')" class="h4" />
            </div>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn outlined block class="loginBtn" @click="loginTwitter()">
            <div class="loginBtnInside d-flex justify-start">
              <v-icon class="mr-3"> fab fa-twitter </v-icon>
              <div v-sanitize="$t('mainlayout.connectwithtwitter')" class="h4" />
            </div>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn outlined block class="loginBtn" @click="loginPopupFB()">
            <div class="loginBtnInside d-flex justify-start">
              <v-icon class="mr-3"> fab fa-facebook </v-icon>
              <div v-sanitize="$t('mainlayout.connectwithfb')" class="h4" />
            </div>
          </v-btn> </v-col></v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="1000">
      {{ snackbartext }}
    </v-snackbar>
  </v-card>
</template>

<script>
import { signup, login, lostPassword } from "@/api/account";

export default {
  name: "LoginPopup",
  props: ["dialog", "customRedirect"],
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      showPass1: false,
      showPass2: false,
      email: null,
      firstName: null,
      lastName: null,
      password1: null,
      password2: null,
      signupForm: false,
      passlostForm: false,
      snackbar: false,
      snackbartextID: null,
    };
  },
  computed: {
    snackbartext() {
      return this.$t("account." + this.snackbartextID)
    },
    submitEnabled() {
      if (this.signupForm) {
        return !(this.password1 === this.password2
          && /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.password1)
          && this.email != null
          && /.+@.+/.test(this.email)
          && this.firstName != null
          && this.lastName != null)

      } else if (this.passlostForm) {
        return !(this.email != null
          && /.+@.+/.test(this.email))

      }
      return !(this.email != null
        && /.+@.+/.test(this.email)
        && this.password1 != null
        && /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(this.password1))
    }
  },
  watch: {},
  methods: {
    loginPopupFB() {
      window.popupWindow = window.open(
        `${this.api}/auth/facebook`,
        "my_window",
        "width=600, height=600"
      );
      this.setTimer();
    },
    loginPopupG() {
      window.popupWindow = window.open(
        `${this.api}/auth/google`,
        "my_window",
        "width=600, height=600"
      );
      this.setTimer();
    },
    loginLinkedin() {
      window.popupWindow = window.open(
        `${this.api}/auth/linkedin`,
        "my_window",
        "width=600, height=600"
      );
      this.setTimer();
    },
    loginTwitter() {
      window.popupWindow = window.open(
        `${this.api}/auth/twitter`,
        "my_window",
        "width=600, height=600"
      );
      this.setTimer();
    },
    connect() {
      login({ email: this.email, password: this.password1 }).then((res) => {
        if (res.status == 200)
          this.redirect("Projects")
        else {
          this.snackbartextID = res.msgId
          this.snackbar = true
        }
      });
    },
    submit() {
      if (this.signupForm) {
        signup({
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password1,
          lang: this.$i18n.locale
        }).then((res) => {
          this.snackbartextID = res.msgId
          this.snackbar = true
        });
      } else if (this.passlostForm) {
        lostPassword({ email: this.email, lang: this.$i18n.locale }).then((res) => {
          this.passlostForm = false
          this.snackbartextID = res.msgId
          this.snackbar = true

        });
      }
    },
    redirect(route) {
      this.$router.push({ name: route });
    },
    setTimer() {
      var timer = setInterval(() => {
        if (window.popupWindow.closed) {
          clearInterval(timer);
          this.dialog = false;
          if (this.customRedirect != null) this.redirect(this.customRedirect);
          else this.redirect("Projects");
        }
      }, 1000);
    },
  },
};
</script>

<style scoped>
.loginBtn>>> {
  width: 100%;
  height: 7vh !important;
  border-color: var(--divider-color) !important;
  background-color: white !important;
  color: var(--text-color) !important;
}
</style>
