import axios from 'axios'

axios.interceptors.response.use(response => {
	var res = {}
	if (typeof response.data === 'object')
		res = response.data
	if (response.status)
		res.status = response.status
	if (response.headers)
		res.headers = response.headers
	return res
}, error => {
	if (error.response) {
		var res = {}
		if (typeof error.response.data === 'object')
			res = error.response.data
		if (error.response.status)
			res.status = error.response.status
		if (error.response.headers)
			res.headers = error.response.headers
		return res
	} else {
		return error;
	}
})

export const fetch = (url, opts = {}) => {
	if (opts.body) {
		opts.data = JSON.stringify(opts.body)
		delete opts.body
	}

	const config = {
		...opts,
		credentials: 'include',
		mode: 'basic',
		headers: {
			...opts.headers,
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	}

	return axios(url, config)
}

export const fetchFile = (url, opts = {}) => {
	if (opts.body) {
		opts.data = JSON.stringify(opts.body)
		delete opts.body
	}

	const config = {
		...opts,
		credentials: 'include',
		headers: {
			...opts.headers,
			Accept: 'application/octet-stream',
			'Content-Type': 'application/octet-stream'
		},
		responseType: 'blob'
	}

	return axios(url, config)
}

export const postMultipart = (url, file) => {
	const formData = new FormData()
	formData.append('file', file)

	const config = {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}

	return axios.post(url, formData, config)
}
