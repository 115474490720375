var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.IsMobile
            ? _c(
                "v-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "v-btn-toggle",
                    {
                      staticClass:
                        "d-flex flex-row justify-center flex-wrap tuto-button-group",
                      attrs: { mandatory: "" },
                      model: {
                        value: _vm.topicIndex,
                        callback: function ($$v) {
                          _vm.topicIndex = $$v
                        },
                        expression: "topicIndex",
                      },
                    },
                    _vm._l(_vm.sections, function (item, index) {
                      return _c(
                        "v-btn",
                        { key: index, attrs: { color: "btn-tuto" } },
                        [
                          _c("div", {}, [
                            _vm._v(" " + _vm._s(item.title) + " "),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-col",
                {
                  staticClass: "mb-n12 mt-n8 text-center",
                  attrs: { lg: "12" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  {
                                    staticClass: "white--text",
                                    attrs: { color: "cta-btn" },
                                  },
                                  on
                                ),
                                [
                                  _c("div", {
                                    directives: [
                                      {
                                        name: "sanitize",
                                        rawName: "v-sanitize",
                                        value: _vm.$t("tutorials.tuto1"),
                                        expression: "$t('tutorials.tuto1')",
                                      },
                                    ],
                                    staticClass: "h3",
                                  }),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        _vm._l(_vm.sections, function (item, index) {
                          return _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  _vm.topicIndex = index
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(" " + _vm._s(item.title) + " "),
                              ]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.topicIndex == 0,
                  expression: "topicIndex == 0",
                },
              ],
              staticClass: "text-center",
              attrs: { sm: "10" },
            },
            [
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding2"),
                      expression: "$t('tutorials.roombuilding2')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding3"),
                      expression: "$t('tutorials.roombuilding3')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664303 } }),
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding4"),
                      expression: "$t('tutorials.roombuilding4')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664358 } }),
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding5"),
                      expression: "$t('tutorials.roombuilding5')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664394 } }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.topicIndex == 1,
                  expression: "topicIndex == 1",
                },
              ],
              staticClass: "text-center",
              attrs: { sm: "10" },
            },
            [
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding6"),
                      expression: "$t('tutorials.roombuilding6')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding7"),
                      expression: "$t('tutorials.roombuilding7')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664262 } }),
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding8"),
                      expression: "$t('tutorials.roombuilding8')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664210 } }),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.topicIndex === 2,
                  expression: "topicIndex === 2",
                },
              ],
              staticClass: "text-center",
              attrs: { sm: "10" },
            },
            [
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding9"),
                      expression: "$t('tutorials.roombuilding9')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.roombuilding10"),
                      expression: "$t('tutorials.roombuilding10')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540665506 } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }