import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
import getBrowserLocale from "@/tools/browserlocale";
import { languages, supportedLocalesInclude } from "@/locale/index.js";

function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });

    if (supportedLocalesInclude(browserLocale)) {
      return browserLocale;
    }
    return "en";
  }

  const messages = Object.assign(languages);

  let locale = getStartingLocale();

  if (localStorage.locale != null) {
    locale = localStorage.locale;
  }

const i18n = new VueI18n({
	locale: 'fr', // set locale
	fallbackLocale: 'en', // set fallback locale
	messages, // set locale messages
	silentTranslationWarn: process.env.NODE_ENV === "PROD"
})

export default i18n;