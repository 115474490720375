<template>
  <div class="cont-video">
    <div v-show="!videoLoaded" class="facade" style="position: relative">
      <v-img :src="`https://vumbnail.com/${src}.png`" cover :aspect-ratio="1" class="facade-child" alt="Video placeholder"></v-img>
    </div>
    <div v-show="videoLoaded" style="padding: 72% 0 0 0; position: relative">
      <iframe class="vimeo-iframe"
      @load="iframeIsLoaded"
       :src="'https://player.vimeo.com/video/' +
        src +
        '?muted=1&badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479=58479&dnt=1&autoplay=' +
        autoplay
        " frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
        style="position: absolute;  top: 0; left: 0; width: 100%; height: 100%" allowfullscreen webkitallowfullscreen
        mozallowfullscreen title="Volumiq V5" />
    </div>
  </div>
</template>
<script>
export default {
  name: "VideoBlock",
  components: {},
  props: {
    src: {
    },
    autoplay: false,
  },
  data() {
    return {
      videoLoaded: false,
    };
  },
  methods: {
    iframeIsLoaded () {
      this.videoLoaded = true;
    }
  },
};
</script>

<style scoped>
.facade-child{
  width: 70% !important;
  margin: 0 auto !important;
  filter: blur(20px);
}
</style>
