var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-col",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMe,
              expression: "showMe",
            },
          ],
          staticClass: "ma-0 pa-0",
          attrs: { sm: "auto" },
        },
        [
          _vm.greyedOut
            ? _c("div", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t(_vm.textCode),
                    expression: "$t(textCode)",
                  },
                ],
                staticClass: "h4 my-1 poppins",
              })
            : _vm.icon != null
            ? _c(
                "div",
                { staticClass: "h4 my-1 poppins" },
                [
                  !_vm.externalLink
                    ? _c("router-link", { attrs: { to: _vm.redirect } }, [
                        _c(
                          "div",
                          { class: _vm.computeClasses },
                          [
                            _c(
                              "v-icon",
                              { staticClass: "mr-2", attrs: { size: "20" } },
                              [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                            ),
                            _c("span", {
                              directives: [
                                {
                                  name: "sanitize",
                                  rawName: "v-sanitize",
                                  value: _vm.$t(_vm.textCode),
                                  expression: "$t(textCode)",
                                },
                              ],
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm.scroller
                    ? _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goto()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.computeClasses },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "20" } },
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "sanitize",
                                    rawName: "v-sanitize",
                                    value: _vm.$t(_vm.textCode),
                                    expression: "$t(textCode)",
                                  },
                                ],
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _c(
                        "v-btn",
                        {
                          attrs: { depressed: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.window.open(
                                _vm.redirection,
                                "_blank",
                                "noopener"
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { class: _vm.computeClasses },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-2", attrs: { size: "20" } },
                                [_vm._v(" " + _vm._s(_vm.icon) + " ")]
                              ),
                              _c("span", {
                                directives: [
                                  {
                                    name: "sanitize",
                                    rawName: "v-sanitize",
                                    value: _vm.$t(_vm.textCode),
                                    expression: "$t(textCode)",
                                  },
                                ],
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "h4 my-1 poppins" },
                [
                  !_vm.externalLink
                    ? _c(
                        "router-link",
                        { attrs: { to: _vm.redirect } },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t(_vm.textCode),
                                expression: "$t(textCode)",
                              },
                            ],
                            class: _vm.computeClasses,
                          }),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.externalLink,
                                  expression: "externalLink",
                                },
                              ],
                              staticClass: "mt-3 mx-1",
                              attrs: { small: "" },
                            },
                            [_vm._v(" mdi-open-in-new ")]
                          ),
                        ],
                        1
                      )
                    : _vm.scroller
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 pa-0 d-flex justify-space-between",
                          attrs: { block: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.goto()
                            },
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t(_vm.textCode),
                                expression: "$t(textCode)",
                              },
                            ],
                            class: _vm.computeClasses,
                          }),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.externalLink,
                                  expression: "externalLink",
                                },
                              ],
                              staticClass: "mt-3 mx-1",
                              attrs: { small: "" },
                            },
                            [_vm._v(" mdi-open-in-new ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-btn",
                        {
                          staticClass: "ma-0 pa-0 d-flex justify-space-between",
                          attrs: { block: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.window.open(
                                _vm.redirection,
                                "_blank",
                                "noopener"
                              )
                            },
                          },
                        },
                        [
                          _c("div", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t(_vm.textCode),
                                expression: "$t(textCode)",
                              },
                            ],
                            class: _vm.computeClasses,
                          }),
                          _c(
                            "v-icon",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.externalLink,
                                  expression: "externalLink",
                                },
                              ],
                              staticClass: "mt-3 mx-1",
                              attrs: { small: "" },
                            },
                            [_vm._v(" mdi-open-in-new ")]
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showMe && _vm.addDivider,
              expression: "showMe && addDivider",
            },
          ],
        },
        [_c("v-divider")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }