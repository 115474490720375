<template>
  <v-col>
    <v-row justify="center">
      <v-col lg="10">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.deco1')" class="tutotext" />
        </div>
        <videoBlock :src="540664689" />
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.deco2')" class="tutotext" />
        </div>
        <videoBlock :src="540664652" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import videoBlock from "@/components/layout/videoBlock";

export default {
  components: { videoBlock },

  props: {},
  data() {
    return {};
  },
  computed: {
  },
  watch: {},
  methods: {},
};
</script>

<style></style>
