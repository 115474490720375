<template>
  <v-col>
    <v-row justify="center">
      <v-col v-if="!IsMobile" lg="12">
        <v-btn-toggle v-model="topicIndex" mandatory class="d-flex flex-row justify-center flex-wrap tuto-button-group">
          <v-btn v-for="(item, index) in sections" :key="index" class="" color="btn-tuto">
            <div class="">
              {{ item.title }}
            </div>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-col v-else lg="12" class="mb-n12 mt-n8 text-center">
        <v-menu offset-y>
          <template #activator="{ on }">
            <v-btn class="white--text" color="cta-btn" v-on="on">
              <div v-sanitize="$t('tutorials.tuto1')" class="h3" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(item, index) in sections" :key="index" @click="topicIndex = index">
              <v-list-item-title>
                {{ item.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col v-show="topicIndex == 0" sm="10" class="text-center">
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.experience1')" class="tutotext" />
        </div>
        <videoBlock :src="540664822" />
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.experience2')" class="tutotext" />
        </div>
        <videoBlock :src="540664877" />
        <div class="text-left my-12 mx-n4 mx-sm-n10">
          <p v-sanitize="$t('tutorials.experience3')" class="tutotext" />
        </div>
        <videoBlock :src="540664925" />
      </v-col>
      <v-col v-show="topicIndex == 1" sm="10" class="text-center">
        <div v-sanitize="$t('tutorials.experience4')" class="text-left my-12 mx-n4 mx-sm-n10" />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import videoBlock from "@/components/layout/videoBlock";

export default {
  components: { videoBlock },

  props: {},
  data() {
    return {
      topicIndex: 0,
    };
  },
  computed: {
    IsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },
    sections() {
      return [
        { title: this.$t("tutorials.experience5") },
        { title: this.$t("tutorials.experience6") },
      ];
    },
  },
  watch: {},
  methods: {},
};
</script>

<style></style>
