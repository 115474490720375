<template>
  <div v-if="enableFooter">
    <v-sheet class="py-12">
      <v-row align="center" justify="center" class="pa-0 ma-4">
        <v-col sm="12" md="4" lg="3" class="text-center d-flex flex-row justify-space-around align-center">
          <img class="logo" width="160" :src="GetImgType('/img/logo/Volumiq_Logox2048.png')" :alt="$t('meta-alt.iconfootervolumiq')" />
          <img class="logo" width="160" :src="GetImgType('/img/logo/logo_utopiq_house_icon.png')" :alt="$t('meta-alt.iconfooterutopiq')" />
        </v-col>
        <v-col sm="12" md="6" lg="5" class="">
          <div class="h4">
            <b v-sanitize="$t('mainlayout.mentions')" />
          </div>
          <div v-sanitize="$t('mainlayout.mentionsdetails')" class="h4" />
          <router-link :to="{ name: 'TermsAndConditions' , params: { lang: locale }}">
            <div class="h4">
              <span v-sanitize="$t('mainlayout.cgu')" class="text-underline" /> -
              <span v-sanitize="$t('mainlayout.private')" class="text-underline" />
            </div>
          </router-link>
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  components: {},

  props: {},
  data() {
    return {
      enableFooter: true,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
  watch: {},
  mounted() {
    this.$root.$on("set-footer-enabled", this.setFooterEnabled);
  },
  beforeDestroy() {
    this.$root.$off("set-footer-enabled", this.setFooterEnabled);
  },
  methods: {
    setFooterEnabled(a) {
      this.enableFooter = a;
    },
  },
};
</script>

<style scoped>
.logo {
  height: 10rem;
}
.text-underline {
  text-decoration: underline;
}
</style>
