import Vuex from 'vuex'
import Vue from 'vue'

Vue.use(Vuex)

export const globalStore = new Vuex.Store({
	state: {
		user: {}
	},
	mutations: {
		increment(state) {
			state.count++
		},
		reloadUser(state, user) {
			state.user = user
		},
		logout(state) {
			state.user = {}
		}
	},
	getters: {
		userHasConfirmedAgreement: (state) => state.user.hasConfirmedAgreement,
		getLicense: (state) => {
			if (state.user == undefined)
				return "base"
			switch (state.user.planLevel) {
				case 0:
					return "base";
				case 1:
					return "plus";
				case 2:
					return "pro";
				case 3:
					return "entreprise";
				default:
					return "base";
			}
		},
	},
	actions: {}
})
