<template>
  <div>
    <v-menu offset-y :close-on-content-click="false" content-class="box-shadow">
      <template #activator="{ on, attrs }">
        <v-btn depressed pla color="transparent" class="pa-2 px-3 btn-menu" :aria-label="$t('meta-alt.localeselectorbtn')" v-bind="attrs" v-on="on">
          <gb-flag :code="langs[selectedLang]" style="width: 40px;" class="ma-0 pa-0 pt-n5" />
        </v-btn>
      </template>
      <v-list class="dropdown pa-2" width="200px">
        <v-list-item>
          <v-row class="flex-row ma-0 pa-0" justify="center" align="center">
            <v-col v-t="'mainlayout.language'" class="ma-0 pa-0 h4 my-1 poppins" /><v-col class="ma-0 pa-0">
              <v-menu offset-y content-class="box-shadow">
                <template #activator="{ on, attrs }">
                  <v-btn depressed pla color="transparent" class="nopointer btn-menu" v-bind="attrs" v-on="on">
                    <gb-flag :code="langs[selectedLang]" style="width: 40px;" />
                  </v-btn>
                </template>
                <v-list class="dropdown pa-2">
                  <v-list-item-group v-model="selectedLang" mandatory >
                    <v-list-item v-for="(item, index) in langs" :key="index" @click="changeLanguage(index)">
                      <v-list-item-title>
                        <gb-flag :code="item" style="width: 40px;" />
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item>
        <v-divider />
        <v-list-item>
          <v-row class="flex-row ma-0 pa-0" justify="center" align="center">
            <v-col v-t="'mainlayout.region'" class="ma-0 pa-0 h4 my-1 poppins" /><v-col class="ma-0 pa-0">
              <v-menu offset-y content-class="box-shadow">
                <template #activator="{ on, attrs }">
                  <v-btn depressed pla color="transparent" class="nopointer btn-menu" v-bind="attrs" v-on="on">
                    <div class="h3 black--text">
                      {{ regions[selectedRegion] }}
                    </div>
                  </v-btn>
                </template>
                <v-list class="dropdown pa-2">
                  <v-list-item-group v-model="selectedRegion" mandatory>
                    <v-list-item v-for="(item, index) in regions" :key="index">
                      <v-list-item-title>
                        <div class="h4">
                          {{ item }}
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      langs: ["fr", "gb"],
      regions: ["EU"],
      selectedLang: 0,
      selectedRegion: 0,
    };
  },
  computed: {
  },
  watch: {
    selectedRegion(newVal) {
      localStorage.region = this.regions[newVal];
    },
  },
  mounted() {
    if (localStorage.region) {
      this.selectedRegion = this.regions.indexOf(localStorage.region);
    }
    this.selectedLang = this.$i18n.locale == "en" ? 1 : 0
  },
  methods: {
    changeLanguage(index) {
      localStorage.lang = index == 0 ? "fr" : "en"
      this.$router.push(`/${index == 0 ? "fr" : "en"}${this.$route.fullPath.substring(3)}`);
    }
  },
};
</script>

<style scoped>
.v-application .v-menu__content {
  box-shadow: none;
}
</style>
