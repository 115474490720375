<template>
  <v-container class="my-0 mx-0 pb-0 px-0 mt-n12" fluid>
    <div v-sanitize="$t('tutorials.selector1')" class="text-center" />
    <v-row class="my-0 mx-0 pb-0 px-0 justify-space-around">
      <v-col cols="12" sm="6" lg="3" class="text-center">
        <videoBlock style="width: 95%" :src="547554110" />
        <div v-sanitize="$t('tutorials.selector2')" class="mt-1" />
      </v-col>
      <v-col cols="12" sm="6" lg="3" class="text-center">
        <videoBlock style="width: 95%" :src="547554148" />
        <div v-sanitize="$t('tutorials.selector3')" class="mt-1" />
      </v-col>
      <v-col cols="12" sm="6" lg="3" class="text-center">
        <videoBlock style="width: 95%" :src="547554183" />
        <div v-sanitize="$t('tutorials.selector4')" class="mt-1" />
      </v-col>
      <v-col cols="12" sm="6" lg="3" class="text-center">
        <videoBlock style="width: 95%" :src="547554236" />
        <div v-sanitize="$t('tutorials.selector5')" class="mt-1" />
      </v-col>
    </v-row>
    <div class="h3 text-center mb-14">
      <b v-sanitize="$t('tutorials.selector6')" />
    </div>
    <v-row class="my-0 mx-md-0 mx-n8 pb-0 px-0 mt-n8">
      <v-col v-if="!IsMobile" cols="12">
        <v-row align="center" justify="space-between" class="max-width">
          <v-col v-for="(save, index) in pictosGrey" :key="index" sm="auto" :class="{ 'selected-save': tutoIndex === index }" class="save-selector mb-n12 text-center" @click="selectTuto(index)">
            <v-img :src="tutoIndex === index
                ? GetImgType(pictosColor[index])
                : GetImgType(pictosGrey[index])
              " class="white--text mx-auto" :class="{
    'selected-save-pic': tutoIndex === index,
  }" contain :aspect-ratio="1 / 1" :width="96" />
            <div class="card-title">
              <h2 class="h3 boldtitle">
                {{ tutosNames[index] }}
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else cols="12">
        <v-slide-group show-arrows>
          <v-slide-item v-for="(save, index) in pictosGrey" :key="index">
            <v-btn class="mx-1" active-class="grey white--text" depressed @click="selectTuto(index)">
              <div class="h3">
                <b>{{ tutosNames[index] }}</b>
              </div>
            </v-btn>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
    <tutorialsBlock :tuto-index="tutoIndex" />
  </v-container>
</template>

<script>
import tutorialsBlock from "@/components/tutorials/tutorialsBlock";
import videoBlock from "@/components/layout/videoBlock";

export default {
  components: { tutorialsBlock, videoBlock },
  props: {},
  data() {
    return {
      tutoIndex: 0,
      pictosGrey: [
        "/img/tutorials/construction_picto.png",
        "/img/tutorials/amenagement_picto1.png",
        "/img/tutorials/decoration_picto.png",
        "/img/tutorials/experience_picto.png",
        "/img/tutorials/finaliser_picto.png",
      ],
      pictosColor: [
        "/img/tutorials/construction_rond.png",
        "/img/tutorials/amenagement_rond.png",
        "/img/tutorials/decoration_rond.png",
        "/img/tutorials/experience_rond.png",
        "/img/tutorials/finaliser_rond.png",
      ],
    };
  },

  computed: {
    IsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        default:
          return false;
      }
    },

    tutosNames() {
      return [
        this.$t("tutorials.selector7"),
        this.$t("tutorials.selector8"),
        this.$t("tutorials.selector9"),
        this.$t("tutorials.selector10"),
        this.$t("tutorials.selector11"),
      ];
    },
  },
  watch: {},
  methods: {
    selectTuto(i) {
      this.tutoIndex = i;
    },
  },
};
</script>

<style>
.tuto-button-group {
  /* background-color: var(--v-lighterBlue-base) !important; */
  background-color: white !important;
}
</style>

<style scoped>
.long-button h3 {
  margin: 0 !important;
  text-transform: none;
}

.card {
  background-color: white;
  padding: 2rem;
  height: 15rem;
}

.textarea {
  height: 15rem;
}

.v-text-field>>>input {
  font-size: 30px !important;
  font-weight: 100;
}

.card-title {
  text-align: center;
  width: 100%;
}

.save-selector {
  padding: 2rem;
}

.selected-save {
  /* background-color: var(--v-lighterBlue-base) !important; */
  background-color: white !important;

  border-top-left-radius: var(--radius) !important;
  border-top-right-radius: var(--radius) !important;
}

.save-selector {
  width: 20%;
  cursor: pointer;
}

.images-block-left-corner {
  border-top-left-radius: 0rem !important;
}
</style>
