var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "pa-0 ma-0", attrs: { id: "app" } },
    [
      _c("topnavbarConfigurator", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.navbarConfigurator,
            expression: "navbarConfigurator",
          },
        ],
        staticClass: "ma-0 pa-0 index",
      }),
      _c("topnavbar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hidePhoneNavbar,
            expression: "hidePhoneNavbar",
          },
        ],
        staticClass: "ma-0 pa-0 index",
        attrs: { dark: true, small: false, "show-news": false, fixed: true },
      }),
      _c("topnavbarMobile", { attrs: { show: _vm.showPhoneNavbar } }),
      _c(
        "div",
        {
          staticClass: "pa-0 ma-0",
          style: !_vm.navbarConfigurator
            ? `min-height: 100vh !important; max-width: 100vw;`
            : `height: 100% !important; max-width: 100vw;`,
          attrs: { id: "content" },
        },
        [
          _vm.showPhoneNavbar
            ? _c("div", { staticClass: "navbar-faker-mobile pa-0 ma-0" })
            : !_vm.navbarConfigurator
            ? _c("div", { staticClass: "navbar-faker pa-0 ma-0" })
            : _vm._e(),
          _c("router-view"),
        ],
        1
      ),
      _c("footer", [_c("pageFooter")], 1),
      _c(
        "vue-cookie-accept-decline",
        {
          ref: "cookietab",
          staticClass: "box-shadow",
          attrs: {
            "element-id": "cookietab",
            debug: false,
            position: "bottom-left",
            type: "floating",
            "disable-decline": false,
            "transition-name": "slideFromBottom",
            "show-postpone-button": false,
          },
          on: {
            status: _vm.cookieStatus,
            "clicked-accept": _vm.cookieClickedAccept,
            "clicked-decline": _vm.cookieClickedDecline,
          },
        },
        [
          _c(
            "div",
            { attrs: { slot: "postponeContent" }, slot: "postponeContent" },
            [_vm._v("×")]
          ),
          _c(
            "div",
            {
              staticClass: "cookietxt",
              attrs: { slot: "message" },
              slot: "message",
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("mainlayout.cookietext")) + " "),
              _c("a", {
                directives: [
                  {
                    name: "sanitize",
                    rawName: "v-sanitize",
                    value: _vm.$t("mainlayout.cookielearnmore"),
                    expression: "$t('mainlayout.cookielearnmore')",
                  },
                ],
                staticClass: "cookieurl",
                attrs: {
                  rel: "noopener,noreferrer",
                  href: "https://cookiesandyou.com/",
                  target: "_blank",
                },
              }),
            ]
          ),
          _c("div", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.$t("mainlayout.cookieno"),
                expression: "$t('mainlayout.cookieno')",
              },
            ],
            staticClass: "cookietxt cookiedecline",
            attrs: { slot: "declineContent" },
            slot: "declineContent",
          }),
          _c("div", {
            directives: [
              {
                name: "sanitize",
                rawName: "v-sanitize",
                value: _vm.$t("mainlayout.cookieyes"),
                expression: "$t('mainlayout.cookieyes')",
              },
            ],
            staticClass: "cookietxt cookieaccept",
            attrs: { slot: "acceptContent" },
            slot: "acceptContent",
          }),
        ]
      ),
      _c("referralModal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }