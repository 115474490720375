<template>
  <v-app id="app" class="pa-0 ma-0">
    <topnavbarConfigurator v-show="navbarConfigurator" class="ma-0 pa-0 index" />

    <topnavbar v-show="hidePhoneNavbar" :dark="true" :small="false" :show-news="false" :fixed="true"
      class="ma-0 pa-0 index" />
    <topnavbarMobile :show="showPhoneNavbar" />
    <div id="content" class="pa-0 ma-0"
      :style="!navbarConfigurator ? `min-height: 100vh !important; max-width: 100vw;` : `height: 100% !important; max-width: 100vw;`">
      <div v-if="showPhoneNavbar" class="navbar-faker-mobile pa-0 ma-0" />
      <div v-else-if="!navbarConfigurator" class="navbar-faker pa-0 ma-0" />
      <router-view />
    </div>
    <footer>
      <pageFooter />
    </footer>
    <vue-cookie-accept-decline :ref="'cookietab'" :element-id="'cookietab'" :debug="false" :position="'bottom-left'"
      :type="'floating'" :disable-decline="false" :transition-name="'slideFromBottom'" :show-postpone-button="false"
      @status="cookieStatus" @clicked-accept="cookieClickedAccept" @clicked-decline="cookieClickedDecline"
      class="box-shadow">
      <div slot="postponeContent">&times;</div>
      <div slot="message" class="cookietxt">
        {{ $t("mainlayout.cookietext") }}
        <a v-sanitize="$t('mainlayout.cookielearnmore')" class="cookieurl" rel="noopener,noreferrer"
          href="https://cookiesandyou.com/" target="_blank" />
      </div>
      <div slot="declineContent" v-sanitize="$t('mainlayout.cookieno')" class="cookietxt cookiedecline" />
      <div slot="acceptContent" v-sanitize="$t('mainlayout.cookieyes')" class="cookietxt cookieaccept" />
    </vue-cookie-accept-decline>
    <referralModal />
  </v-app>
</template>

<script>
import VueCookieAcceptDecline from "vue-cookie-accept-decline";
import topnavbar from "@/components/layout/navigation/topnavbar";
import topnavbarConfigurator from "@/components/layout/navigation/topnavbar-configurator";
import topnavbarMobile from "@/components/layout/navigation/topnavbar-phone";
import pageFooter from "@/components/layout/pageFooter";
import "vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css";
import referralModal from "@/components/other/referralModal";
import { updateAccountInfos } from "@/api/account";

export default {
  name: "App",
  metaInfo() {
    const lang = this.$i18n.locale
    const titleTemplate = this.$t("meta-alt.titletemplate")
    const desc = this.$t("meta-alt.descriptionvolumiq")
    const metadesc = this.$t("meta-alt.description")
    const keywords = this.$t("meta-alt.keywords")
    const title = this.$t("meta-alt.title")
    const api = process.env.VUE_APP_API_URL
    return {
      title: "Volumiq",
      titleTemplate: titleTemplate,
      htmlAttrs: {
        lang: lang,
      },
      link: [
        { rel: 'preconnect', href: api },
        { rel: 'manifest', href: '/site.webmanifest', crossorigin: "use-credentials" },
        { rel: 'canonical', href: ('https://volumiq.com' + this.$route.path).replace('/en', '').replace('/fr', '') },
        { rel: 'alternate', hreflang: "en", href: 'https://volumiq.com' + this.$router.resolve({ name: this.$route.name, params: { lang: "en" } }).href },
        { rel: 'alternate', hreflang: "fr", href: 'https://volumiq.com' + this.$router.resolve({ name: this.$route.name, params: { lang: "fr" } }).href },
      ],
      meta: [
        { name: "keywords", content: keywords },
        { name: "author", content: "Volumiq Software" },
        { name: "twitter:card", content: "summary_large_image" },
        { name: "twitter:site", content: "@Utopiq_Volumiq" },
        { name: "twitter:title", content: title },
        { name: "twitter:image", content: "https://volumiq.com/img/index/imagemeta.jpg" },
        { name: "twitter:description", content: metadesc },
        { property: "og:title", content: title },
        { property: "og:url", content: "Volumiq" },
        { property: "og:type", content: "Volumiq" },
        { property: "og:locale", content: lang },
        { property: "og:description", content: metadesc },
        { property: "og:image", content: "https://volumiq.com/img/index/imagemeta.jpg" },
        { name: "content-language", content: lang },
        { name: "theme-color", content: "#fffefc" },
        { name: "description", content: desc }]
    }
  },
  components: {
    topnavbar,
    pageFooter,
    topnavbarConfigurator,
    topnavbarMobile,
    VueCookieAcceptDecline,
    referralModal,
  },

  data() {
    return {
      navbarConfigurator: false,
      window,
      referralSharedDialog: false,
      lastScrollPosition: 0,
    };
  },
  computed: {
    IsMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
        case "md":
          return true;
        default:
          return false;
      }
    },
    hidePhoneNavbar() {
      return !this.navbarConfigurator && !this.IsMobile;
    },
    showPhoneNavbar() {
      if (this.navbarConfigurator) return false;
      return !this.hidePhoneNavbar;
    },
  },

  watch: {},
  mounted() {
    this.$root.$on("set-config-navbar", this.setNavbarConfig);
    window.addEventListener("scroll", this.onScroll);
  },
  updated() {
  },
  beforeDestroy() {
    this.$root.$off("set-config-navbar", this.setNavbarConfig);
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    showReferralSharedDialog(a) {
      this.referralSharedDialog = true;
      this.$root.$off("show-referral-shared-dialog");
    },
    disableReferralSharedDialog(a) {
      this.$userStore.state.user.referralUsedPopup = false;
      updateAccountInfos({
        User: {
          referralUsedPopup: false,
        },
      });
    },

    setNavbarConfig(a) {
      this.navbarConfigurator = a;
    },
    cookieClickedDecline() {
      window["ga-disable-UA-130941775-1"] = true;
    },
    cookieClickedAccept() {
      window.dataLayer = window.dataLayer || [];
      window["ga-disable-UA-130941775-1"] = false;
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag("js", new Date());
      gtag("config", "UA-130941775-1");
    },
    cookieStatus(statut) {
      if (statut == "accept") {
        this.cookieClickedAccept();
      } else if (statut == "decline") {
        this.cookieClickedDecline();
      }
    },
    onScroll() {
      this.lastScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      this.$root.$emit("set-navbar-scrolled", this.lastScrollPosition > 10);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/utopiq.scss";
@import "@/styles/index.scss";

.shadowed {
  filter: drop-shadow(0px 1px 1px #1a1a1a);
}

.shadowed .h1,
.shadowed .h2,
.shadowed .h3,
.shadowed .h4,
.shadowed .h5,
.shadowed .h6,
.shadowed .h7 {
  text-shadow: #1a1a1a 0px 1px 1px;
}

.nobgblock .shadowed {
  filter: drop-shadow(0px 1px 1px #1a1a1a);
}

.nobgblock .shadowed .h1,
.nobgblock .shadowed .h2,
.nobgblock .shadowed .h3,
.nobgblock .shadowed .h4,
.nobgblock .shadowed .h5,
.nobgblock .shadowed .h6,
.nobgblock .shadowed .h7 {
  text-shadow: #1a1a1a 0px 1px 1px;
}

.navbar-faker-mobile {
  width: 100%;
  height: 70px !important;
}

.navbar-faker {
  min-height: 7vh !important;
}

.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}

#app {
  background-color: var(--bg-color);
}

#app::-webkit-scrollbar {
  display: none;
}

html {
  overflow-y: auto;
}

#pagecontent {
  max-height: 100vh;
  background-color: var(--bg-color);
  overflow-x: hidden;
  overflow-y: auto;
}
</style>

<style scoped>
#content {
  flex: 1 !important;
  position: relative;
}
</style>

<style scoped>
.cookieurl {
  color: var(--cta-color) !important;
}

.cookieurl:hover {
  color: var(--cta-color-hover) !important;
}

.cookieurl:focus {
  color: var(--cta-color-pressed) !important;
}

.cookietxt {
  font-family: "Inter-Medium" !important;
}

.cookiedecline {
  color: var(--text-color) !important;
}

.cookieaccept {
  color: white !important;
}

#cookietab {
  width: 22rem !important;
  max-width: none;
  border-radius: var(--radius);
  border: none;
}
</style>

<style>
.cookie__floating,
.cookie__floating__buttons__button {
  background-color: var(--bg-dropdown-color) !important;
  border-color: var(--divider-color) !important;
}

.cookie__floating__buttons__button--accept {
  background: var(--cta-color) !important;
}

.cookie__floating__buttons__button--accept:hover {
  background: var(--cta-color-hover) !important;
}

.cookie__floating__buttons__button--accept:focus {
  background: var(--cta-color-pressed) !important;
}

.cookie__floating__buttons__button--decline:hover {
  background: var(--bg-selected) !important;
}

.v-card.v-sheet {
  background-color: rgb(255, 255, 255);
}
</style>
