<template>
  <div>
    <v-dialog v-model="newAccDialogReferral" transition="dialog-bottom-transition" max-width="600" persistent>
      <v-card class="popup">
        <v-row class="poprows text-center ma-0 pa-12 pb-7" align="center" justify="center">
          <v-col cols="12">
            <div class="h2">
              <b v-sanitize="$t('account.referral')" />
            </div>
          </v-col>
          <v-col cols="12" class="mt-n5">
            <h3 v-sanitize="$t('account.referraluse', { nb: 1000 })" />
          </v-col>
          <v-col cols="12" xl="12">
            <v-text-field v-model="referralcode" color="black" hide-details dense outlined placeholder="VOLUMIQ - #### - #### - ####" />
          </v-col>
          <v-col cols="6">
            <v-btn v-sanitize="$t('global.ok')" class="cta-btn white--text" @click="sendReferral(referralcode)" />
          </v-col>
          <v-col cols="6">
            <v-btn v-sanitize="$t('global.ignore')" class="cta-btn white--text" @click="sendReferral(null)" />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="referralSuccessModal" transition="dialog-bottom-transition" max-width="600">
      <mainCoinsModal :nb="1000" :is-bonus="true" :is-referral="true" />
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="1000">
      <h3 v-sanitize="$t('account.referralinvalid')" />
    </v-snackbar>
  </div>
</template>

<script>
import { referral } from "@/api/account";
import mainCoinsModal from "@/components/other/mainCoinsModal";
import { getMe } from "@/api/account";

export default {
  components: {
    mainCoinsModal,
  },
  props: {},
  data() {
    return {
      referralSuccessModal: false,
      snackbar: false,
      newAccDialogReferral: false,
      referralcode: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$root.$on("show-new-account-referral", this.showNewAccountReferral);
  },
  methods: {
    showNewAccountReferral(a) {
      if (this.$userStore.state.user != undefined)
        this.newAccDialogReferral = true;
      else this.newAccDialogReferral = false;
    },
    sendReferral(e) {
      referral(e).then((result) => {
        switch (result.code) {
          case "success":
            getMe();
            this.referralSuccessModal = true;
            this.newAccDialogReferral = false;
            break;
          case "error":
            this.snackbar = true;
            break;
          case "close":
            this.newAccDialogReferral = false;
            break;

          default:
            break;
        }
      });
    },
  },
};
</script>

<style scoped></style>
