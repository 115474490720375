var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enableFooter
    ? _c(
        "div",
        [
          _c(
            "v-sheet",
            { staticClass: "py-12" },
            [
              _c(
                "v-row",
                {
                  staticClass: "pa-0 ma-4",
                  attrs: { align: "center", justify: "center" },
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass:
                        "text-center d-flex flex-row justify-space-around align-center",
                      attrs: { sm: "12", md: "4", lg: "3" },
                    },
                    [
                      _c("img", {
                        staticClass: "logo",
                        attrs: {
                          width: "160",
                          src: _vm.GetImgType(
                            "/img/logo/Volumiq_Logox2048.png"
                          ),
                          alt: _vm.$t("meta-alt.iconfootervolumiq"),
                        },
                      }),
                      _c("img", {
                        staticClass: "logo",
                        attrs: {
                          width: "160",
                          src: _vm.GetImgType(
                            "/img/logo/logo_utopiq_house_icon.png"
                          ),
                          alt: _vm.$t("meta-alt.iconfooterutopiq"),
                        },
                      }),
                    ]
                  ),
                  _c(
                    "v-col",
                    { attrs: { sm: "12", md: "6", lg: "5" } },
                    [
                      _c("div", { staticClass: "h4" }, [
                        _c("b", {
                          directives: [
                            {
                              name: "sanitize",
                              rawName: "v-sanitize",
                              value: _vm.$t("mainlayout.mentions"),
                              expression: "$t('mainlayout.mentions')",
                            },
                          ],
                        }),
                      ]),
                      _c("div", {
                        directives: [
                          {
                            name: "sanitize",
                            rawName: "v-sanitize",
                            value: _vm.$t("mainlayout.mentionsdetails"),
                            expression: "$t('mainlayout.mentionsdetails')",
                          },
                        ],
                        staticClass: "h4",
                      }),
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "TermsAndConditions",
                              params: { lang: _vm.locale },
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "h4" }, [
                            _c("span", {
                              directives: [
                                {
                                  name: "sanitize",
                                  rawName: "v-sanitize",
                                  value: _vm.$t("mainlayout.cgu"),
                                  expression: "$t('mainlayout.cgu')",
                                },
                              ],
                              staticClass: "text-underline",
                            }),
                            _vm._v(" - "),
                            _c("span", {
                              directives: [
                                {
                                  name: "sanitize",
                                  rawName: "v-sanitize",
                                  value: _vm.$t("mainlayout.private"),
                                  expression: "$t('mainlayout.private')",
                                },
                              ],
                              staticClass: "text-underline",
                            }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }