var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-col",
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { lg: "10" } },
            [
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.deco1"),
                      expression: "$t('tutorials.deco1')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664689 } }),
              _c("div", { staticClass: "text-left my-12 mx-n4 mx-sm-n10" }, [
                _c("p", {
                  directives: [
                    {
                      name: "sanitize",
                      rawName: "v-sanitize",
                      value: _vm.$t("tutorials.deco2"),
                      expression: "$t('tutorials.deco2')",
                    },
                  ],
                  staticClass: "tutotext",
                }),
              ]),
              _c("videoBlock", { attrs: { src: 540664652 } }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }