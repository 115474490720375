var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-col",
        {
          staticClass: "background-color navbar-area-sm ma-0 pa-0 elevation-3",
          attrs: { cols: "12" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-0 pa-0 mb-",
              attrs: { align: "center", justify: "space-around" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-column justify-center flex-sm-row align-center inner-content",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h2 utopiq-logo titre d-inline-flex align-center",
                    },
                    [
                      _c("v-img", {
                        staticClass: "mr-1",
                        attrs: {
                          contain: "",
                          "max-width": "28px",
                          src: _vm.GetImgType("/img/logo/Volumiq_Logox64.png"),
                        },
                      }),
                      _c("h4", { staticClass: "ma-0 pa-0" }, [
                        _vm._v("Volumiq design system"),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-column justify-space-around flex-sm-row align-center",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _vm.isLogged
                    ? _c(
                        "v-row",
                        {
                          staticClass: "text-center",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c("v-btn", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("project.volumiqprojects"),
                                expression: "$t('project.volumiqprojects')",
                              },
                            ],
                            staticClass: "h4 my-2 pointer btn-menu",
                            attrs: {
                              depressed: "",
                              pla: "",
                              color: "transparent",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.redirectTo({ name: "Projects" })
                              },
                            },
                          }),
                          _c("v-icon", { staticClass: "mx-3" }, [
                            _vm._v(" mdi-chevron-right "),
                          ]),
                          _c(
                            "v-btn",
                            {
                              staticClass: "h4 my-2 pointer btn-menu",
                              attrs: {
                                depressed: "",
                                pla: "",
                                color: "transparent",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.redirectTo(_vm.redirectProjet)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.name) + " ")]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        {
                          staticClass: "text-center",
                          attrs: { align: "center", justify: "center" },
                        },
                        [
                          _c("v-btn", {
                            directives: [
                              {
                                name: "sanitize",
                                rawName: "v-sanitize",
                                value: _vm.$t("mainlayout.returnhome"),
                                expression: "$t('mainlayout.returnhome')",
                              },
                            ],
                            staticClass: "h4 my-2 pointer btn-menu",
                            attrs: {
                              depressed: "",
                              pla: "",
                              color: "transparent",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.redirectTo({ name: "Volumiq" })
                              },
                            },
                          }),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex flex-column justify-start flex-sm-row align-center",
                  attrs: { cols: "12", sm: "auto" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mx-4" },
                    [
                      _vm.pinterest
                        ? _c(
                            "v-dialog",
                            {
                              attrs: {
                                width: "70vw",
                                "min-height": "70vh",
                                "content-class": "round",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c("div", { staticClass: "h4 mx-12" }, [
                                          _c(
                                            "a",
                                            _vm._g(
                                              _vm._b(
                                                {
                                                  directives: [
                                                    {
                                                      name: "sanitize",
                                                      rawName: "v-sanitize",
                                                      value: _vm.$t(
                                                        "project.mypinterestboard"
                                                      ),
                                                      expression:
                                                        "$t('project.mypinterestboard')",
                                                    },
                                                  ],
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: _vm.reloadPinterest,
                                                  },
                                                },
                                                "a",
                                                attrs,
                                                false
                                              ),
                                              on
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1454453271
                              ),
                            },
                            [
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "pa-6 text-center scroll",
                                    staticStyle: { height: "75vh" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "h4 boldtitle mr-5 mt-0" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("project.mypinterestboard")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "a",
                                          {
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.window.open(
                                                  _vm.pinterest,
                                                  "_blank",
                                                  "noopener"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "black--text mx-3 mt-n2",
                                              },
                                              [
                                                _vm._v(
                                                  "fas fa-external-link-alt "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "pincontainer" }, [
                                      _c("a", {
                                        attrs: {
                                          "data-pin-do": "embedBoard",
                                          "data-pin-lang": "fr",
                                          href: _vm.pinterest,
                                          "data-pin-scale-height": "640",
                                        },
                                      }),
                                    ]),
                                  ]
                                ),
                              ],
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mx-2" },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: {
                            "max-width": "80vw",
                            "content-class": "round",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c("div", { staticClass: "h4 mx-12" }, [
                                    _c(
                                      "a",
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { href: "#" } },
                                          "a",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("mainlayout.guide")) +
                                            " "
                                        ),
                                        _c("v-icon", { staticClass: "mt-n1" }, [
                                          _vm._v("fas fa-info-circle "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          [
                            _c(
                              "v-card",
                              { staticClass: "tutodialog scroll pa-12" },
                              [
                                _c("tutorialSelector", {
                                  staticClass: "pa-12 mb-12",
                                }),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }