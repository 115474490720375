var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-app-bar",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "background-color",
          style: `max-width: 100vw;`,
          attrs: {
            app: "",
            "clipped-left": "",
            flat: "",
            dark: "",
            "elevate-on-scroll": "",
            "scroll-target": "#content",
          },
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "text--color1",
              style: "position: absolute; left: 40px; top: 20px",
              attrs: { depressed: "", text: "" },
              on: {
                click: function ($event) {
                  _vm.drawer = true
                },
              },
            },
            [
              _c(
                "div",
                [
                  _c("v-icon", { staticClass: "mr-2", attrs: { size: "20" } }, [
                    _vm._v(" fas fa-bars "),
                  ]),
                  _c("b", [_vm._v(" Menu")]),
                ],
                1
              ),
            ]
          ),
          _c(
            "v-row",
            {
              staticClass: "ma-0 pa-0",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                {
                  staticClass:
                    "d-flex justify-center align-center inner-content ma-0 pa-0",
                  attrs: { cols: "12", sm: "4" },
                },
                [
                  _c("router-link", { attrs: { to: "/" } }, [
                    _vm.isRouteUtopiq
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ma-0 pa-0 d-flex justify-center align-center",
                          },
                          [
                            _c("v-img", {
                              staticClass: "mr-3",
                              attrs: {
                                contain: "",
                                "max-width": "28px",
                                src: _vm.GetImgType(
                                  "/img/logo/logo_utopiq_house_icon.png"
                                ),
                              },
                            }),
                            _c("div", {
                              directives: [
                                {
                                  name: "sanitize",
                                  rawName: "v-sanitize",
                                  value: _vm.$t("mainlayout.utopiqtitle"),
                                  expression: "$t('mainlayout.utopiqtitle')",
                                },
                              ],
                              staticClass: "h4 ma-0 pa-0 active",
                            }),
                          ],
                          1
                        )
                      : _vm.isRouteVolumiq
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ma-0 pa-0 d-flex justify-center align-center",
                          },
                          [
                            _c("v-img", {
                              staticClass: "mr-3",
                              attrs: {
                                contain: "",
                                "max-width": "28px",
                                src: _vm.GetImgType(
                                  "/img/logo/Volumiq_Logox64.png"
                                ),
                              },
                            }),
                            _c("div", {
                              directives: [
                                {
                                  name: "sanitize",
                                  rawName: "v-sanitize",
                                  value: _vm.$t("mainlayout.volumiqtitle"),
                                  expression: "$t('mainlayout.volumiqtitle')",
                                },
                              ],
                              staticClass: "h4 ma-0 pa-0 active",
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "ma-0 pa-0 d-flex justify-center align-center",
                          },
                          [
                            _c("v-img", {
                              staticClass: "mr-3",
                              attrs: {
                                contain: "",
                                "max-width": "28px",
                                src: _vm.GetImgType(
                                  "/img/logo/logo_utopiq_house_icon.png"
                                ),
                              },
                            }),
                            _c("v-img", {
                              attrs: {
                                contain: "",
                                "max-width": "28px",
                                src: _vm.GetImgType(
                                  "/img/logo/Volumiq_Logox64.png"
                                ),
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show",
            },
          ],
          staticClass: "background-color",
          style: `margin-top: 70px`,
          attrs: {
            clipped: "",
            app: "",
            height: `calc(100vh - ${_vm.$vuetify.application.top}px)`,
          },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "d-flex flex-column align-center ma-0 justify-start",
              staticStyle: { height: "85%" },
            },
            [
              _c(
                "v-col",
                { staticClass: "mt-12", attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    staticClass: "float-left mt-1",
                    class: { grayscale: !_vm.isRouteVolumiq },
                    attrs: {
                      contain: "",
                      "max-width": "28px",
                      src: _vm.GetImgType("/img/logo/Volumiq_Logox64.png"),
                    },
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-x": "",
                        "content-class": "box-shadow box-radius",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs, value }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "ma-0 pa-1 nopointer btn-menu",
                                      attrs: {
                                        depressed: "",
                                        pla: "",
                                        color: "transparent",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("h4", {
                                    directives: [
                                      {
                                        name: "sanitize",
                                        rawName: "v-sanitize",
                                        value: _vm.$t(
                                          "mainlayout.volumiqtitle"
                                        ),
                                        expression:
                                          "$t('mainlayout.volumiqtitle')",
                                      },
                                    ],
                                    staticClass: "ma-0 pa-0",
                                    class: { active: _vm.isRouteVolumiq },
                                  }),
                                  value
                                    ? _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-left "),
                                      ])
                                    : _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-right "),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "dropdown pa-2" },
                        [
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "project.volumiqprojects",
                              redirection: "Projects",
                              "need-logged": true,
                              "hide-logged": false,
                              "external-link": false,
                              "add-divider": true,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.theapp",
                              redirection: "Volumiq",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.tutorials",
                              redirection: "Tutorials",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.pricing",
                              redirection: "Pricing",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.blog",
                              redirection: "Blog",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "retail.b2bmaintitle",
                              redirection: "Retail",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale != "fr",
                                expression: "$i18n.locale != 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.roadmap",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale == "fr",
                                expression: "$i18n.locale == 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.roadmap",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vR60R3Aogejsl6ONumRIjGH6ox_-7Ybg2gbxv422CKlxHZdpP4ECkvH1VeJcWkR4Hw1I-XghJ6zH1c1/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale != "fr",
                                expression: "$i18n.locale != 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.changelog",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale == "fr",
                                expression: "$i18n.locale == 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.changelog",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vTSvklfwilfiGnBnte82k-omaTaI9Lrk-P0RHJE3Crg5qsACMJ_pi5qXmSPkFbsrU7JsHdpPovEHLvT/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale != "fr",
                                expression: "$i18n.locale != 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.integration",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.$i18n.locale == "fr",
                                expression: "$i18n.locale == 'fr'",
                              },
                            ],
                            attrs: {
                              "text-code": "doc.integration",
                              "need-logged": false,
                              "external-link": true,
                              redirection:
                                "https://docs.google.com/document/d/e/2PACX-1vR2zyL31Sc31VI9rFb7w50Uc7H8t9SudflVaZKFPa3c-N-WQUUZKZS2HJfb0y0ySJuktBC8Ey_NHNyg/pub",
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "credits.credits",
                              redirection: "Credits",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-3", attrs: { cols: "auto" } },
                [
                  _c("v-img", {
                    staticClass: "float-left mt-1",
                    class: { grayscale: !_vm.isRouteUtopiq },
                    attrs: {
                      contain: "",
                      "max-width": "28px",
                      src: _vm.GetImgType(
                        "/img/logo/logo_utopiq_house_icon.png"
                      ),
                    },
                  }),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-x": "",
                        "content-class": "box-shadow box-radius",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs, value }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "ma-0 pa-1 nopointer btn-menu",
                                      attrs: {
                                        depressed: "",
                                        pla: "",
                                        color: "transparent",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("h4", {
                                    directives: [
                                      {
                                        name: "sanitize",
                                        rawName: "v-sanitize",
                                        value: _vm.$t(
                                          "mainlayout.volumiqgames"
                                        ),
                                        expression:
                                          "$t('mainlayout.volumiqgames')",
                                      },
                                    ],
                                    staticClass: "ma-0 pa-0",
                                    class: { active: _vm.isRouteUtopiq },
                                  }),
                                  value
                                    ? _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-left "),
                                      ])
                                    : _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-right "),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "dropdown pa-2" },
                        [
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.utopiqtitle",
                              redirection: "Utopiq",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.neighborhoodtitle",
                              redirection: "Neighborhood",
                              "need-logged": false,
                              "external-link": false,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "mt-3 flex-grow-1", attrs: { cols: "auto" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-x": "",
                        "content-class": "box-shadow box-radius",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs, value }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "ma-0 pa-1 nopointer btn-menu",
                                      attrs: {
                                        depressed: "",
                                        pla: "",
                                        color: "transparent",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("h4", {
                                    directives: [
                                      {
                                        name: "sanitize",
                                        rawName: "v-sanitize",
                                        value: _vm.$t("mainlayout.community"),
                                        expression:
                                          "$t('mainlayout.community')",
                                      },
                                    ],
                                    staticClass: "ma-0 pa-0",
                                  }),
                                  value
                                    ? _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-left "),
                                      ])
                                    : _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-right "),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "dropdown pa-2" },
                        [
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.discord",
                              "need-logged": false,
                              "external-link": true,
                              redirection: "https://discord.gg/MN2X66RvW9",
                              icon: "fab fa-discord",
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.twitter",
                              "need-logged": false,
                              "external-link": true,
                              redirection: "https://twitter.com/Utopiq_Volumiq",
                              icon: "fab fa-twitter",
                            },
                          }),
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.pinterest",
                              "need-logged": false,
                              "external-link": true,
                              redirection: "https://www.pinterest.fr/volumiq/",
                              icon: "fab fa-pinterest",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.isLogged
                ? _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-dialog",
                        {
                          attrs: { "max-width": "100vw" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-btn",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass:
                                              "white--text mb-5 mb-sm-0 cta-btn mr-3",
                                            attrs: { "x-large": "" },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("div", { staticClass: "h2 my-2" }, [
                                          _c("b", {
                                            directives: [
                                              {
                                                name: "sanitize",
                                                rawName: "v-sanitize",
                                                value:
                                                  _vm.$t("mainlayout.connect"),
                                                expression:
                                                  "$t('mainlayout.connect')",
                                              },
                                            ],
                                          }),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2407134444
                          ),
                          model: {
                            value: _vm.dialog,
                            callback: function ($$v) {
                              _vm.dialog = $$v
                            },
                            expression: "dialog",
                          },
                        },
                        [_c("loginModal")],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-col",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLogged,
                      expression: "isLogged",
                    },
                  ],
                  staticClass: "mt-3",
                  attrs: { cols: "auto" },
                },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-x": "",
                        "content-class": "box-shadow box-radius",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs, value }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass:
                                        "ma-0 pa-1 nopointer btn-menu",
                                      attrs: {
                                        depressed: "",
                                        pla: "",
                                        color: "transparent",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _c("h4", { staticClass: "ma-0 pa-0" }, [
                                    _vm._v(" " + _vm._s(_vm.fullname) + " "),
                                  ]),
                                  value
                                    ? _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-left "),
                                      ])
                                    : _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v(" mdi-chevron-right "),
                                      ]),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "dropdown pa-2" },
                        [
                          _c("navbarRedirect", {
                            attrs: {
                              "text-code": "mainlayout.myaccount",
                              redirection: "Account",
                              "need-logged": true,
                              "hide-logged": false,
                              "external-link": false,
                            },
                          }),
                          _c("v-divider"),
                          _c(
                            "div",
                            {
                              staticClass: "h4 my-1 poppins",
                              on: {
                                click: function ($event) {
                                  return _vm.logoff()
                                },
                              },
                            },
                            [
                              _c("a", { attrs: { href: "#" } }, [
                                _c("div", {
                                  directives: [
                                    {
                                      name: "sanitize",
                                      rawName: "v-sanitize",
                                      value: _vm.$t("mainlayout.deconnect"),
                                      expression: "$t('mainlayout.deconnect')",
                                    },
                                  ],
                                  staticClass: "pa-2 dropdown-item",
                                }),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [_c("localeSelector", { staticClass: "ma-0 pa-0" })],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }